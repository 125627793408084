import { useEffect, useRef, useState } from "react";
import "./Kalendarz.css";
import {
	Button,
	Form,
	FormControl,
	FormGroup,
	FormLabel,
	FormSelect,
	Modal,
	OverlayTrigger,
	Table,
	Row,
	Col,
	ModalFooter,
} from "react-bootstrap";
import { apiUrl, mode } from "../../globsls";
import ReactSelect from "react-select";
import AddWydarzenieForm from "./AddWydarzenieForm";

const months = [
	"Styczeń",
	"Luty",
	"Marzec",
	"Kwiecień",
	"Maj",
	"Czerwiec",
	"Lipiec",
	"Sierpień",
	"Wrzesień",
	"Październik",
	"Listopad",
	"Grudzień",
];

function getDaysInMonth(month, year) {
	var date = new Date(year, month, 1);
	let dayOfWeek = date.getDay();

	// Adjust so that the first day starts on Monday (1) instead of Sunday (0)
	// If the first day is Sunday, move back to the previous Monday (day 1)
	let offset = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
	date.setDate(date.getDate() + offset);

	var days = [];
	for (let i = 0; i < 6; i++) {
		days.push([]);
		for (let j = 0; j < 7; j++) {
			days[i].push(new Date(date));
			date.setDate(date.getDate() + 1);
		}
	}
	return days;
}

function formatDate(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [year, month, day].join("-");
}

export default function Kalendarz(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	const currentDate = new Date();
	const [month, setMonth] = useState(currentDate.getMonth());
	const [year, setYear] = useState(currentDate.getFullYear());
	const [card, setCard] = useState([]);
	const [wydarzenia, setWydarzenia] = useState([]);
	useEffect(() => {
		setCard(getDaysInMonth(month, year));
	}, [month, year]);
	function changeHandler(option) {
		let buffer = month;
		if (option === 1) {
			buffer++;
		} else if (option === -1) {
			buffer--;
		}
		if (buffer === 12) {
			setYear((a) => a + 1);
			setMonth(0);
		} else if (buffer === -1) {
			setYear((a) => a - 1);
			setMonth(11);
		} else {
			setMonth(buffer);
		}
	}
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getWydarzenia");
		form.append("biogazownia", selBiogaz.id);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setWydarzenia(res));
	}, [selBiogaz, refresh]);
	function filtrWyd(element, data) {
		var dateParts = element.data.split("-");
		var elemDate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
		if (elemDate > data) {
			return false;
		}
		if (element.koniec && element.koniec !== "0000-00-00") {
			var date2Parts = element.koniec.split("-");
			var elem2Date = new Date(
				+date2Parts[0],
				date2Parts[1] - 1,
				+date2Parts[2]
			);
			if (elem2Date < data) {
				return false;
			}
		}
		switch (element.cyklicznosc) {
			case "d":
				return true;
			case "w":
				if (elemDate.getDay() === data.getDay()) {
					return true;
				} else {
					return false;
				}
			case "m":
				if (elemDate.getDate() === data.getDate()) {
					return true;
				} else {
					return false;
				}
			case "k":
				if (
					elemDate.getMonth() % 3 === data.getMonth() % 3 &&
					elemDate.getDate() === data.getDate()
				) {
					return true;
				} else {
					return false;
				}
			case "y":
				if (
					elemDate.getDate() === data.getDate() &&
					elemDate.getMonth() === data.getMonth()
				) {
					return true;
				} else {
					return false;
				}
			default:
				if (
					elemDate.getDate() === data.getDate() &&
					elemDate.getFullYear() === data.getFullYear() &&
					elemDate.getMonth() === data.getMonth()
				) {
					return true;
				} else {
					return false;
				}
		}
	}
	return (
		<div className="Kalendarz">
			<h4>Kalendarz biogazownika</h4>
			<Table
				bordered
				style={{ borderRadius: "1em", tableLayout: "fixed" }}
				striped
			>
				<thead>
					<tr>
						<th colSpan={7} style={{ height: "10px !important" }}>
							<center>
								<h2>
									<Button
										variant="none"
										style={{ float: "left", fontWeight: "bold" }}
										onClick={() => changeHandler(-1)}
									>
										{"<"}
									</Button>
									{months[month]} {year}
									<Button
										variant="none"
										style={{ float: "right", fontWeight: "bold" }}
										onClick={() => changeHandler(1)}
									>
										{">"}
									</Button>
								</h2>
							</center>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr id="weekdays">
						<th>poniedziałek</th>
						<th>wtorek</th>
						<th>środa</th>
						<th>czwartek</th>
						<th>piątek</th>
						<th>sobota</th>
						<th>niedziela</th>
					</tr>
					{card.map((a, i) => (
						<tr key={i}>
							{a.map((b, i) => (
								<th
									key={i}
									style={{
										padding: "0.25rem",
										backgroundColor:
											b.getDate() === currentDate.getDate() &&
											b.getFullYear() === currentDate.getFullYear() &&
											b.getMonth() === currentDate.getMonth() &&
											"#ff000029",
									}}
								>
									<Kratka
										dane={b}
										month={month}
										year={year}
										// wydarzenia={wydarzenia[0].filter((c) => filtrWyd(c, b))}
										wydarzenia={wydarzenia.map((d) =>
											d.filter((c) => filtrWyd(c, b))
										)}
										selBiogaz={selBiogaz}
										loggedIn={loggedIn}
										reload={reload}
									></Kratka>
								</th>
							))}
						</tr>
					))}
				</tbody>
			</Table>
			<br></br>
		</div>
	);
}
function Kratka(props) {
	const { dane, month, wydarzenia, selBiogaz, loggedIn, reload } = props;
	const [show, setShow] = useState(false);
	return (
		<div
			className={"kratka"}
			style={{
				color: dane.getMonth() !== month ? "gray" : "black",
			}}
			onMouseEnter={() => setShow(true)}
			onMouseLeave={() => setShow(false)}
		>
			<p
				style={{
					fontSize: "xx-small",
					width: "100%",
					margin: "0",
					filter: dane.getMonth() !== month ? "opacity(0.5)" : "",
				}}
			>
				{dane.getDate()}
			</p>
			<div
				className="wydarzenia"
				style={{ filter: dane.getMonth() !== month ? "opacity(0.5)" : "" }}
			>
				{wydarzenia.map((b, j) =>
					b.map((a, i) => (
						<Wydarzenie
							uprawnienia={j === 0 ? true : false}
							key={i}
							data={dane}
							dane={a}
							reload={reload}
							hideDodaj={setShow}
						></Wydarzenie>
					))
				)}
				{show && loggedIn.typ !== "3" && (
					<Dodaj
						selBiogaz={selBiogaz}
						data={dane}
						reload={reload}
						loggedIn={loggedIn}
						setShow2={setShow}
					></Dodaj>
				)}
			</div>
		</div>
	);
}
function Wydarzenie(props) {
	function generateStyle(wariant) {
		let buffer = "";
		// if (wariant.includes("serwis")) {
		// 	buffer += "serwis";
		// } else if (wariant.includes("zalecenie")) {
		// 	buffer += "zalecenie";
		// } else if (wariant.includes("badanie")) {
		// 	buffer += "badanie";
		// } else if (wariant.includes("admin")) {
		// 	buffer += "admin";
		// } else {
		// 	return wariant;
		// }
		if (wariant.includes("^t")) {
			buffer += " technologiczny";
		} else if (wariant.includes("^k")) {
			buffer += " kogeneracji";
		} else if (wariant.includes("^b")) {
			buffer += " biologiczny";
		} else if (wariant.includes("^f")) {
			buffer += " biologiczny";
		} else if (wariant.includes("^p")) {
			buffer += " biologiczny";
		}
		if (wariant.includes("%h")) {
			buffer += " historyczny";
		}
		return buffer;
	}
	const { dane, data, reload } = props;
	const [show, setShow] = useState(false);
	function delHandler() {
		const form = new FormData();
		form.append("action", "delWydarzenie");
		form.append("id", dane.id);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.text())
			.then(() => {
				reload((a) => !a);
				setShow(false);
			});
	}
	const renderOverlay = () => (
		<div
			style={{
				position: "fixed",
				backgroundColor: "whitesmoke",
				padding: "0.5rem",
			}}
		>
			<h4>{dane.nazwa}</h4>
			<div>{dane.opis}</div>
		</div>
	);
	return (
		<div className="wydContainer">
			{/* <OverlayTrigger
				placement="bottom-start"
				delay={{ show: 1500, hide: 500 }}
				overlay={renderOverlay()}
			> */}
			<Button
				variant={dane.wariant || "secondary"}
				className={"wydarzenie" + " " + generateStyle(dane.wariant)}
				onClick={() => {
					setShow(true);
					props.hideDodaj(false);
				}}
				style={{
					display: "flex",
					marginBottom: "0.25rem",
					alignContent: "center",
					alignItems: "center",
				}}
			>
				<div
					style={{
						fontSize: "smaller",
						marginBottom: "0.25rem",
						textShadow: "0px 0px 3px black",
						color: "white",
						overflow: "hidden",
						fontWeight: "bold",
					}}
				>
					{dane.nazwa}
				</div>
			</Button>
			{/* </OverlayTrigger> */}
			<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{dane.nazwa}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{" "}
					<h4>{formatDate(data)}</h4> {dane.opis}
				</Modal.Body>
				<ModalFooter>
					{props.uprawnienia && (
						<Button variant="danger" onClick={() => delHandler()}>
							Usuń
						</Button>
					)}

					<Button variant="secondary" onClick={() => setShow(false)}>
						Zamknij
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export function KalendarzForm(props) {
	const { loggedIn, reload, refresh } = props;
	const [data, setData] = useState(null);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [biogazownie, setBiogazownie] = useState([]);
	useEffect(() => {
		const form2 = new FormData();
		form2.append("action", "getBiogazownie");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form2,
		})
			.then((res) => res.json())
			.then((res) => setBiogazownie(res));
	}, [refresh]);
	const handleChange = (selected) => {
		if (selected && selected.some((option) => option.value === "select-all")) {
			if (selectedOptions.length === biogazownie.length) {
				setSelectedOptions([]);
			} else {
				setSelectedOptions(biogazownie);
			}
		} else {
			setSelectedOptions(selected);
		}
	};
	return (
		<div>
			<Form>
				<Row>
					<FormGroup as={Col}>
						<FormLabel>Data</FormLabel>
						<FormControl
							type="date"
							onChange={(event) => setData(event.target.value)}
						></FormControl>
					</FormGroup>
					<FormGroup
						as={Col}
						style={{
							paddingBottom: "0.5em",
						}}
					>
						<FormLabel>Biogazownie</FormLabel>
						<ReactSelect
							isMulti
							options={biogazownie.map((a) => ({
								value: a.id,
								label: a.nazwa,
							}))}
							value={selectedOptions}
							onChange={handleChange}
							closeMenuOnSelect={false}
						></ReactSelect>
						<Button
							onClick={() =>
								setSelectedOptions(
									biogazownie.map((a) => ({
										value: a.id,
										label: a.nazwa,
									}))
								)
							}
						>
							Wybierz wszystkie
						</Button>
					</FormGroup>
				</Row>
				<Row>
					<Dodaj
						data={new Date(data)}
						reload={reload}
						loggedIn={loggedIn}
						selectedOptions={selectedOptions}
					></Dodaj>
				</Row>
			</Form>
		</div>
	);
}

function Dodaj(props) {
	const { selBiogaz, data, reload, loggedIn, selectedOptions, setShow2 } =
		props;
	const [show, setShow] = useState(false);
	const nazwaRef = useRef(null);
	const opisRef = useRef(null);
	const rodzajRef = useRef(null);
	const dataRef = useRef(null);
	const kolorRef = useRef(null);
	function submit(event) {
		if (selBiogaz) {
			const form = new FormData();
			form.append("action", "addWydarzenia");
			form.append("user", JSON.stringify(loggedIn));
			form.append("biogazownia", selBiogaz.id);
			form.append("dataStart", formatDate(data));
			form.append("nazwa", nazwaRef.current.value);
			form.append("opis", opisRef.current.value);
			form.append("rodzaj", rodzajRef.current.value);
			form.append("dataStop", dataRef.current.value || formatDate(data));
			form.append("kolor", kolorRef.current.value);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => reload((a) => !a));
			setShow(false);
			setShow2(false);
		} else {
			selectedOptions.forEach((a) => {
				const form = new FormData();
				form.append("action", "addWydarzenia");
				form.append("user", JSON.stringify(loggedIn));
				form.append("biogazownia", a.value);
				form.append("dataStart", formatDate(data));
				form.append("nazwa", nazwaRef.current.value);
				form.append("opis", opisRef.current.value);
				form.append("rodzaj", rodzajRef.current.value);
				form.append("dataStop", dataRef.current.value || formatDate(data));
				form.append("kolor", kolorRef.current.value);
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				}).then(() => reload((a) => !a));
			});
		}
	}
	const render = (props) => (
		<div
			style={{
				position: "fixed",
				backgroundColor: "whitesmoke",
				padding: "0.5rem",
			}}
		>
			dodaj wydarzenie
		</div>
	);
	const opcje = {
		21: [
			["zalecenie^f", "Badanie fermentacji"],
			["zalecenie^p", "Inne badanie"],
		],
		1: [["admin", "Zdarzenie admina"]],
	};
	if (selBiogaz) {
		return (
			<div className="wydContainer">
				<OverlayTrigger
					placement="bottom-start"
					delay={{ show: 250, hide: 400 }}
					overlay={render()}
				>
					<Button
						style={{
							backgroundColor: "lightblue",
							display: "flex",
							marginBottom: "0.25rem",
							alignContent: "center",
							justifyContent: "center",
							justifyItems: "center",
							alignItems: "center",
						}}
						className="wydarzenie dodajWyd"
						onClick={() => setShow(true)}
					></Button>
				</OverlayTrigger>
				<Modal
					show={show}
					onHide={() => {
						setShow(false);
						setShow2(false);
					}}
					dialogClassName="custom-modal-width" // Add this prop
				>
					<AddWydarzenieForm
						selBiogaz={selBiogaz}
						data={data}
						reload={reload}
						loggedIn={loggedIn}
					></AddWydarzenieForm>
				</Modal>
			</div>
		);
	} else {
		return (
			<Form>
				<FormGroup>
					<FormLabel>Nazwa wydarzenia</FormLabel>
					<FormControl type="text" required ref={nazwaRef}></FormControl>
				</FormGroup>
				<FormGroup>
					<FormLabel>Opis wydarzenia</FormLabel>
					<FormControl as="textarea" ref={opisRef}></FormControl>
				</FormGroup>
				<FormGroup>
					<FormLabel>Cykliczność</FormLabel>
					<Row>
						<Col>
							<FormLabel>Rodzaj</FormLabel>
							<FormSelect ref={rodzajRef}>
								<option value="">brak</option>
								<option value={"m"}>co miesiąc</option>
								<option value={"w"}>co tydzień</option>
								<option value={"d"}>codziennie</option>
								<option value={"y"}>co roku</option>
								<option value={"k"}>co kwartał</option>
							</FormSelect>
						</Col>
						<Col>
							<FormLabel>Do</FormLabel>
							<FormControl type="date" ref={dataRef}></FormControl>
						</Col>
					</Row>
				</FormGroup>
				<FormGroup>
					<FormLabel>Kolor</FormLabel>
					<FormSelect ref={kolorRef}>
						<option value="^b">Serwis biologiczny</option>
						<option value="^t">Serwis biologiczny</option>
						<option value="^k">Serwis kogeneracji</option>
						<option value="secondary">Zwykłe zdarzenie</option>
					</FormSelect>
				</FormGroup>
				<Button variant="primary" onClick={() => submit()}>
					Dodaj
				</Button>
			</Form>
		);
	}
}
