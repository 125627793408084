import {
  Button,
  Col,
  Form,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Table,
} from "react-bootstrap";
import "./AdminCzesci.css";
import Czesci from "../../components/Czesci";
import { apiUrl, mode } from "../../../globsls";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";

export default function AdminCzesci(props) {
  const { selBiogaz, loggedIn, reload, refresh } = props;
  const typRef = useRef(null);
  const prodRef = useRef(null);
  const kategoriaRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [typy, setTypy] = useState([]);
  const [typyPre, setTypyPre] = useState([]);
  const [producenci, setProducenci] = useState([]);
  const [kategorie, setKategorie] = useState([]);
  const [jk, setJk] = useState([]);
  useEffect(() => {
    const form = new FormData();
    form.append("action", "getCzesciTypy");
    fetch(apiUrl, {
      mode: mode,
      method: "POST",
      body: form,
    })
      .then((res) => res.json())
      .then((res) => {
        setTypy(
          Object.values(
            Object.groupBy(
              res
                .map((a) => ({
                  ...a,
                  kategoria: a.kategoria ? a.kategoria : "0",
                }))
                .filter((b) => b.kategoria),
              ({ kategoria }) => kategoria
            )
          )
        );
      });
  }, [refresh]);
  useEffect(() => {
    const form = new FormData();
    form.append("action", "getProducenci");
    fetch(apiUrl, {
      mode: mode,
      method: "POST",
      body: form,
    })
      .then((res) => res.json())
      .then((res) => setProducenci(res));
    const form2 = new FormData();
    form2.append("action", "getJk");
    fetch(apiUrl, {
      mode: mode,
      method: "POST",
      body: form2,
    })
      .then((res2) => res2.json())
      .then((res2) => setJk(res2));
  }, [refresh]);
  function submitHandler(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("action", "addCzesc");
    form.append("producent", prodRef.current.getValue()[0].value);
    form.append("typ", typRef.current.getValue()[0].value);
    console.log(...form.entries());
    fetch(apiUrl, {
      mode: mode,
      method: "POST",
      body: form,
    })
      .then((res) => res.text())
      .then((res) => console.log(res))
      .then(() => reload((a) => !a));
  }
  useEffect(() => {
    const form = new FormData();
    form.append("action", "getCzesciKategorie");
    fetch(apiUrl, {
      mode: mode,
      method: "POST",
      body: form,
    })
      .then((res) => res.json())
      .then((res) => setKategorie(res));
  }, [refresh]);
  function typHandler(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("action", "addTyp");
    form.append("kategoria", kategoriaRef.current.getValue()[0].value);
    fetch(apiUrl, {
      mode: mode,
      method: "POST",
      body: form,
    })
      .then((res) => res.text())
      .then((res) => console.log(res))
      .then(() => reload((a) => !a));
  }
  function kategoriaHandler(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("action", "addKategoria");
    fetch(apiUrl, {
      mode: mode,
      method: "POST",
      body: form,
    })
      .then((res) => res.text())
      .then((res) => console.log(res))
      .then(() => reload((a) => !a));
  }
  function jednostkaHandler(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("action", "addJk");
    fetch(apiUrl, {
      mode: mode,
      method: "POST",
      body: form,
    })
      .then((res) => res.text())
      .then((res) => console.log(res))
      .then(() => reload((a) => !a));
  }
  function producentHandler(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("action", "addProducent");
    fetch(apiUrl, {
      mode: mode,
      method: "POST",
      body: form,
    })
      .then((res) => res.text())
      .then((res) => console.log(res))
      .then(() => reload((a) => !a));
  }
  function delHandler(id, typ) {
    if (
      window.confirm(
        "czy na pewno chcesz usunąć parametr części i wszystkie inne (części lub i parametry), które go używają?"
      )
    ) {
      const form = new FormData();
      form.append("id", id);
      switch (typ) {
        case "typ":
          form.append("action", "delTyp");
          break;
        case "kategoria":
          form.append("action", "delKategoria");
          break;
        case "jk":
          form.append("action", "delJk");
          break;
        case "producent":
          form.append("action", "delProducent");
          break;
        default:
      }
      fetch(apiUrl, {
        mode: mode,
        method: "POST",
        body: form,
      })
        .then((res) => res.text())
        .then((res) => console.log(res))
        .then(() => reload((a) => !a));
    }
  }
  console.log(jk);
  return (
    <div className="AdminCzesci">
      <center>
        <h4>Części</h4>
      </center>
      <div style={{ textAlign: "left" }}>
        <Row>
          <Col>
            <AdminCzesciBio
              loggedIn={loggedIn}
              reload={reload}
              refresh={refresh}
              selBiogaz2={selBiogaz}
            ></AdminCzesciBio>
          </Col>
          <Col>
            <AdminCzesciJk
              loggedIn={loggedIn}
              reload={reload}
              refresh={refresh}
              selBiogaz2={selBiogaz}
            ></AdminCzesciJk>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Dodaj część</h4>
            <Form onSubmit={submitHandler}>
              <Row>
                <FormGroup as={Col}>
                  <FormLabel>Typ</FormLabel>
                  <Select
                    ref={typRef}
                    options={typy.map((a) => ({
                      label:
                        a[0].nazwakategorii ||
                        "typy części jednostki kogeneracyjnej",
                      options: a.map((b) => ({ value: b.id, label: b.nazwa })),
                    }))}
                    required
                  ></Select>
                </FormGroup>
                <FormGroup as={Col}>
                  <FormLabel>Nazwa</FormLabel>
                  <FormControl name="nazwa" placeholder="nazwa"></FormControl>
                </FormGroup>
              </Row>
              <Row>
                <FormGroup as={Col}>
                  <FormLabel required>Numer aK</FormLabel>
                  <FormControl
                    required
                    name="ak"
                    placeholder="numer ak"
                  ></FormControl>
                </FormGroup>
                <FormGroup as={Col}>
                  <FormLabel>producent</FormLabel>
                  <Select
                    required
                    ref={prodRef}
                    options={producenci.map((a) => ({
                      value: a.id,
                      label: a.nazwa,
                    }))}
                  ></Select>
                </FormGroup>
              </Row>
              <Row>
                <FormGroup as={Col}>
                  <FormLabel>Specyfikacja</FormLabel>
                  <FormControl
                    name="spec"
                    placeholder="specyfikacja"
                    required
                  ></FormControl>
                </FormGroup>
                <FormGroup as={Col}>
                  <FormLabel>Link do sklepu</FormLabel>
                  <FormControl name="sklep" placeholder="sklep"></FormControl>
                </FormGroup>
                <FormGroup as={Col} hidden>
                  <FormLabel>Uwagi</FormLabel>
                  <FormControl name="uwagi" placeholder="uwagi"></FormControl>
                </FormGroup>
              </Row>
              <FormGroup>
                <Button type="submit">Dodaj</Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Dodaj typ</h4>
            <Form onSubmit={typHandler}>
              <FormGroup>
                <FormLabel>Kategoria</FormLabel>
                <Select
                  ref={kategoriaRef}
                  options={[
                    { label: "Część jednostki kogeneracyjnej", value: "" },
                    {
                      label: "używane w biogazowniach",
                      options: kategorie.map((a) => ({
                        value: a.id,
                        label: a.nazwa,
                      })),
                    },
                  ]}
                ></Select>
              </FormGroup>
              <FormGroup>
                <FormLabel>nazwa</FormLabel>
                <FormControl name="nazwa" placeholder="nazwa"></FormControl>
              </FormGroup>
              <FormGroup>
                <Button type="submit">Dodaj</Button>
              </FormGroup>
            </Form>
          </Col>
          <Col>
            <h4>Dodaj kategorię</h4>
            <Form onSubmit={kategoriaHandler}>
              <FormGroup>
                <FormLabel>nazwa</FormLabel>
                <FormControl name="nazwa" placeholder="nazwa"></FormControl>
              </FormGroup>
              <FormGroup>
                <Button type="submit">Dodaj</Button>
              </FormGroup>
            </Form>
          </Col>
          <Col>
            <h4>Dodaj jednostkę kogeneracyjną</h4>
            <Form onSubmit={jednostkaHandler}>
              <FormGroup>
                <FormLabel>nazwa</FormLabel>
                <FormControl name="nazwa" placeholder="nazwa"></FormControl>
              </FormGroup>
              <FormGroup>
                <Button type="submit">Dodaj</Button>
              </FormGroup>
            </Form>
          </Col>
          <Col>
            <h4>Dodaj producenta</h4>
            <Form onSubmit={producentHandler}>
              <FormGroup>
                <FormLabel>nazwa</FormLabel>
                <FormControl name="nazwa" placeholder="nazwa"></FormControl>
              </FormGroup>
              <FormGroup>
                <Button type="submit">Dodaj</Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Usuń typ</h4>
            <Table>
              <tbody>
                {typy.map((a) => (
                  <>
                    <tr>
                      <th colSpan={10}>
                        {a[0].nazwakategorii ||
                          "typy części jednostki kogeneracyjnej"}
                      </th>
                    </tr>
                    {a.map((b) => (
                      <tr>
                        <td>{b.nazwa}</td>
                        <td>
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => delHandler(b.id, "typ")}
                          >
                            Usuń
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col>
            <h4>Usuń kategorie</h4>
            {kategorie.map((b) => (
              <tr>
                <td>{b.nazwa}</td>
                <td>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => delHandler(b.id, "kategoria")}
                  >
                    Usuń
                  </Button>
                </td>
              </tr>
            ))}
          </Col>
          <Col>
            <h4>Usuń jednostkę kogeneracyjną</h4>
            {jk.map((b, i) => (
              <tr key={i}>
                <td>{b.nazwa}</td>
                <td>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => delHandler(b.id, "jk")}
                  >
                    Usuń
                  </Button>
                </td>
              </tr>
            ))}
          </Col>
          <Col>
            <h4>Usuń producenta</h4>
            {producenci.map((b) => (
              <tr>
                <td>{b.nazwa}</td>
                <td>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => delHandler(b.id, "producent")}
                  >
                    Usuń
                  </Button>
                </td>
              </tr>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
}

function AdminCzesciBio(props) {
  const { selBiogaz2, loggedIn, reload, refresh } = props;
  return (
    <div>
      <Czesci
        loggedIn={loggedIn}
        reload={reload}
        refresh={refresh}
        typ="technologiczny"
        uprawnienia
        selBiogaz2={selBiogaz2}
      ></Czesci>
    </div>
  );
}
function AdminCzesciJk(props) {
  const { selBiogaz2, loggedIn, reload, refresh } = props;
  return (
    <div>
      <Czesci
        loggedIn={loggedIn}
        reload={reload}
        refresh={refresh}
        typ="kogeneracji"
        uprawnienia
        selBiogaz2={selBiogaz2}
      ></Czesci>
    </div>
  );
}
