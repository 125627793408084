import "./AdminKonta.css";
import { mode, apiUrl, uploadsUrl } from "../../../globsls";
import { useEffect, useState, useRef } from "react";
import {
	Button,
	Col,
	Form,
	FormControl,
	FormGroup,
	FormLabel,
	FormSelect,
	Modal,
	ModalBody,
	ModalHeader,
	Overlay,
	Row,
	Table,
	Image,
	Tooltip,
	ModalFooter,
} from "react-bootstrap";
import { createPortal } from "react-dom";

export default function AdminKonta(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	const [konta, setKonta] = useState([]);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getKonta");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setKonta(res));
	}, [loggedIn, refresh]);

	return (
		<div className="AdminKonta">
			<h4>Konta</h4>
			<Table bordered striped hover>
				<thead>
					<tr>
						<th>L.p</th>
						<th>Nazwa</th>
						<th>Email</th>
						<th>Nr Tel</th>
						<th>Typ</th>
						<th>Zdjęcie</th>
						<th>Opcje</th>
					</tr>
				</thead>
				<tbody>
					{konta.map((a, i) => (
						<KontoRow key={i} dane={a} reload={reload}></KontoRow>
					))}
				</tbody>
			</Table>
			<KontoForm reload={reload}></KontoForm>
		</div>
	);
}
function KontoRow({ dane, reload }) {
	const [show, setShow] = useState(false);
	const [konta, setKonta] = useState([]);
	const typy = {
		1: "Administrator",
		2: "Serwis",
		21: "Serwis biologiczny",
		22: "Serwis technologiczny",
		23: "Serwis kogeneracji",
		3: "Inwestor",
	};
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getKonta");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setKonta(res));
	}, []);
	function delHandler() {
		if (
			window.confirm(
				`Czy na pewno chcesz usunąć konto ${dane.nazwa}? Ta zmiana jest nieodwracalna!`
			)
		) {
			const form = new FormData();
			form.append("action", "delKonto");
			form.append("id", dane.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => reload((a) => !a));
		}
	}
	const [obraz, setObraz] = useState(false);
	const target = useRef(null);
	const [zmien, setZmien] = useState(false);
	const nazwaRef = useRef(null);
	const plikRef = useRef(null);
	const emailRef = useRef(null);
	const telRef = useRef(null);
	const typRef = useRef(null);
	const hasloRef = useRef(null);
	const [uploading, setUploading] = useState(false);
	function submitHandler() {
		if (zmien) {
			const form = new FormData();
			setUploading(true);
			form.append("action", "uploadFile");
			form.append("sciezka", "/banery/");
			form.append("plik", plikRef.current.files[0]);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => {
					const form = new FormData();
					form.append("action", "updateKonto");
					form.append("nazwa", nazwaRef.current.value);
					form.append("typ", typRef.current.value);
					form.append("email", emailRef.current.value);
					form.append("haslo", hasloRef.current.value);
					form.append("nazwa", nazwaRef.current.value);
					form.append("obraz", res);
					form.append("id", dane.id);
					fetch(apiUrl, {
						mode: mode,
						method: "POST",
						body: form,
					})
						.then((res) => res.text())
						.then((res) => console.log(res))
						.then(() => reload((a) => !a))
						.then(() => {
							setShow(false);
							setUploading(false);
						});
				});
		} else {
			const form = new FormData();
			form.append("action", "updateKonto");
			form.append("nazwa", nazwaRef.current.value);
			form.append("typ", typRef.current.value);
			form.append("tel", telRef.current.value);
			form.append("email", emailRef.current.value);
			form.append("haslo", hasloRef.current.value);
			form.append("nazwa", nazwaRef.current.value);
			form.append("id", dane.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => reload((a) => !a))
				.then(() => setShow(false));
		}
	}
	return (
		<>
			<tr>
				<td>{dane.id}</td>
				<td>{dane.nazwa}</td>
				<td>{dane.email}</td>
				<td>{dane.tel}</td>
				<td>{typy[dane.typ]}</td>
				<td
					onMouseEnter={() => setObraz(true)}
					onMouseLeave={() => setObraz(false)}
				>
					<a
						ref={target}
						href={uploadsUrl + dane.obraz}
						target="_BLANK"
						rel="noreferrer"
					>
						{dane.obraz}
					</a>
					<Overlay target={target.current} show={obraz} placement="bottom">
						{(props) => (
							<div {...props}>
								<Image
									rounded
									style={{ maxWidth: "400px", maxHeight: "300px" }}
									src={uploadsUrl + dane.obraz}
								></Image>
							</div>
						)}
					</Overlay>
				</td>
				<td style={{ width: "180px" }}>
					<Row>
						<Col>
							<Button variant="primary" size="sm" onClick={() => setShow(true)}>
								Edytuj
							</Button>
						</Col>
						<Col>
							<Button variant="danger" size="sm" onClick={() => delHandler()}>
								Usuń
							</Button>
						</Col>
					</Row>
				</td>
			</tr>
			<Modal show={show} onHide={() => setShow(false)}>
				<ModalHeader>
					Edycja konta {dane.id} ({dane.nazwa})
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup>
							<FormLabel>Nazwa</FormLabel>
							<FormControl
								name="nazwa"
								type="text"
								ref={nazwaRef}
								placeholder="nazwa konta"
								defaultValue={dane.nazwa}
							></FormControl>
						</FormGroup>
						<FormGroup style={{ color: "red" }}>
							<FormLabel>Hasło (zostaw puste by nie zmieniać!)</FormLabel>
							<FormControl
								style={{ backgroundColor: "#ff000021" }}
								name="haslo"
								type="text"
								ref={hasloRef}
								placeholder="haslo konta (zostaw puste by nie zmieniać!)"
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>Email</FormLabel>
							<FormControl
								name="email"
								type="text"
								ref={emailRef}
								placeholder="email konta"
								defaultValue={dane.email}
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>Nr Tel</FormLabel>
							<FormControl
								name="tel"
								type="text"
								ref={telRef}
								placeholder="Numer telefonu"
								defaultValue={dane.tel}
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>Typ konta</FormLabel>
							<FormSelect
								name="typ"
								type="text"
								ref={typRef}
								defaultValue={dane.typ}
							>
								<option value="1">Admin</option>
								<option value="3">Inwestor</option>
								<option value="21">Serwis biologiczny</option>
								<option value="22">Serwis technologiczny</option>
								<option value="23">Serwis kogeneracji</option>
							</FormSelect>
						</FormGroup>
						<FormGroup>
							<FormLabel>Zdjęcie</FormLabel>
							<Row>
								<Col md="auto">
									<Button onClick={() => setZmien((a) => !a)}>
										{zmien ? "Anuluj zmianę" : "Zmień zdjęcie"}
									</Button>
								</Col>
								<Col hidden={!zmien}>
									<FormControl
										type="file"
										name="plik"
										ref={plikRef}
									></FormControl>
								</Col>
							</Row>
						</FormGroup>
					</Form>
				</ModalBody>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Anuluj
					</Button>
					<Button
						variant="primary"
						onClick={() => submitHandler()}
						disabled={uploading}
					>
						{uploading ? "Przesyłanie..." : "Zapisz zmiany"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
function KontoForm({ reload }) {
	const [konta, setKonta] = useState([]);
	const nazwaRef = useRef(null);
	const hasloRef = useRef(null);
	const plikRef = useRef(null);
	const emailRef = useRef(null);
	const telRef = useRef(null);
	const typRef = useRef(null);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getKonta");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setKonta(res));
	}, []);
	const [uploading, setUploading] = useState(false);
	function submitHandler(event) {
		event.preventDefault();
		if (plikRef.current.files.length > 0) {
			const form = new FormData(event.target);
			setUploading(true);
			form.append("action", "uploadFile");
			form.append("sciezka", "/profile/");
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => {
					const form = new FormData(event.target);
					form.append("action", "addKonto");
					form.append("obraz", res);
					form.delete("plik");
					console.log(...form.entries());
					fetch(apiUrl, {
						mode: mode,
						method: "POST",
						body: form,
					})
						.then((res) => res.text())
						.then(() => {
							reload((a) => !a);
							setUploading(false);
						});
				});
		} else {
			const form = new FormData(event.target);
			form.append("action", "addKonto");
			form.delete("plik");
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then(() => reload((a) => !a));
		}
	}

	return (
		<div style={{ textAlign: "left" }}>
			<Form onSubmit={submitHandler}>
				<Row>
					<FormGroup as={Col}>
						<FormLabel>Nazwa</FormLabel>
						<FormControl
							ref={nazwaRef}
							name="nazwa"
							type="text"
							placeholder="nazwa konta"
						></FormControl>
					</FormGroup>
					<FormGroup as={Col}>
						<FormLabel>Hasło</FormLabel>
						<FormControl
							ref={hasloRef}
							name="haslo"
							type="text"
							placeholder="początkowe hasło konta"
						></FormControl>
					</FormGroup>
				</Row>
				<Row>
					<FormGroup as={Col}>
						<FormLabel>Email</FormLabel>
						<FormControl
							ref={emailRef}
							name="email"
							type="text"
							placeholder="Email konta"
						></FormControl>
					</FormGroup>
					<FormGroup as={Col}>
						<FormLabel>Nr Tel</FormLabel>
						<FormControl
							ref={telRef}
							name="tel"
							type="text"
							placeholder="Numer telefonu"
						></FormControl>
					</FormGroup>
				</Row>
				<Row>
					<FormGroup as={Col}>
						<FormLabel>Obraz</FormLabel>
						<FormControl type="file" name="plik" ref={plikRef}></FormControl>
					</FormGroup>
					<FormGroup as={Col}>
						<FormLabel>Typ konta</FormLabel>
						<FormSelect ref={typRef} name="typ">
							<option value="1">Admin</option>
							<option value="3">Inwestor</option>
							<option value="21">Serwis biologiczny</option>
							<option value="22">Serwis technologiczny</option>
							<option value="23">Serwis kogeneracji</option>
						</FormSelect>
					</FormGroup>
				</Row>
				<Row>
					<Col>
						<Button type="submit">
							{uploading ? "Przesyłanie..." : "Dodaj konto"}
						</Button>
					</Col>
				</Row>
			</Form>
		</div>
	);
}
