import { Col, Row } from "react-bootstrap";
import "./Dokumenty.css";

import FileSystem from "../../components/FileSystem";

export default function Dokumenty(props) {
  const { selBiogaz, loggedIn, reload, refresh } = props;
  return (
    <div className="Dokumenty">
      <Row>
        <Col>
          <FileSystem
            selBiogaz={selBiogaz}
            loggedIn={loggedIn}
            reload={reload}
            refresh={refresh}
          ></FileSystem>
        </Col>
      </Row>
    </div>
  );
}
