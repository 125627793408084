import "./Alerty.css";
import { useRef, useState, useEffect } from "react";
import { apiUrl, mode } from "../../../globsls";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Button,
	Col,
	Form,
	FormControl,
	FormLabel,
	Modal,
	ModalHeader,
	Row,
} from "react-bootstrap";

export default function Alerty(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	const [alerty, setAlerty] = useState([]);
	const [show, setShow] = useState(false);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getAlerty");
		form.append("id", selBiogaz.id);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setAlerty(res));
	}, [selBiogaz, refresh]);
	function readAlert(id) {
		const form = new FormData();
		form.append("action", "odczytajAlert");
		form.append("id", id);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.text())
			.then((res) => console.log(res))
			.then(() => reload((a) => !a));
	}
	return (
		<div className="Alerty">
			<h4>Alerty</h4>
			<div>
				<Accordion>
					{alerty
						.sort((a, b) => a.odczytano - b.odczytano)
						.map((a, i) => (
							<AccordionItem
								key={i}
								eventKey={i}
								className={a.odczytano === "0" ? "dangerAcc" : "warningAcc"}
							>
								<AccordionHeader>{a.tytul}</AccordionHeader>
								<AccordionBody>
									<div>{a.tresc}</div>
									{a.odczytano === "0" && (
										<div
											onClick={() => readAlert(a.id)}
											style={{
												display: "flex",
												alignItems: "flex-end",
												width: "100%",
												cursor: "pointer",
											}}
										>
											<span>
												<u>
													<b>Zapoznałem się z problemem</b>
												</u>
											</span>
										</div>
									)}
								</AccordionBody>
							</AccordionItem>
						))}
				</Accordion>
			</div>
			{loggedIn.typ < "3" && (
				<Button onClick={() => setShow(true)} style={{ marginTop: "0.5rem" }}>
					Edytuj
				</Button>
			)}
			<Modal show={show} onHide={() => setShow(false)}>
				<ModalHeader>
					<Modal.Title>Edytuj alerty</Modal.Title>
				</ModalHeader>
				<Modal.Body>
					{alerty.map((a, i) => (
						<FormEl dane={a} key={i} reload={reload}></FormEl>
					))}
					<br></br>
					<AddEl
						selBiogaz={selBiogaz}
						reload={reload}
						loggedIn={loggedIn}
					></AddEl>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Zakończ
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
function FormEl(props) {
	const { dane, reload } = props;
	function delHandler() {
		const form = new FormData();
		form.append("action", "delAlert");
		form.append("id", dane.id);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.text())
			.then(() => reload((a) => !a));
	}

	return (
		<div>
			<Row>
				<Col>{dane.tresc} </Col>
				<Col md="auto">
					<Button
						variant="outline-danger"
						size="sm"
						onClick={() => delHandler()}
					>
						Usuń
					</Button>
				</Col>
			</Row>
		</div>
	);
}
function AddEl(props) {
	const { selBiogaz, reload, loggedIn } = props;
	const trescRef = useRef(null);
	const tytulRef = useRef(null);
	function addHandler() {
		const form = new FormData();
		form.append("action", "addAlert");
		form.append("id", selBiogaz.id);
		form.append("tresc", trescRef.current.value);
		console.log(tytulRef.current.value);
		form.append("tytul", tytulRef.current.value);
		form.append("user", JSON.stringify(loggedIn));
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.text())
			.then((res) => {
				console.log(res);
				reload((a) => !a);
			});
	}
	return (
		<div>
			<Form>
				<FormLabel>Tytuł alertu</FormLabel>
				<FormControl
					as="textarea"
					style={{ height: "38px" }}
					ref={tytulRef}
				></FormControl>
				<FormLabel>Treść alertu</FormLabel>
				<FormControl
					as="textarea"
					style={{ height: "38px" }}
					ref={trescRef}
				></FormControl>
				<Button onClick={() => addHandler()}>Dodaj</Button>
			</Form>
		</div>
	);
}
