import "./SortIndicator.css";

export default function SortIndicator({ mode }) {
	switch (mode) {
		case "desc":
			return (
				<span className="SortIndicator">
					<span className="sortContainer">
						<span>▲</span>
						<span style={{ color: "lightgray" }}>▼</span>
					</span>
				</span>
			);
		case "asc":
			return (
				<span className="SortIndicator">
					<span className="sortContainer">
						<span style={{ color: "lightgray" }}>▲</span>
						<span>▼</span>
					</span>
				</span>
			);
		default:
			return (
				<span className="SortIndicator">
					<span className="sortContainer">
						<span style={{ color: "lightgray" }}>▲</span>
						<span style={{ color: "lightgray" }}>▼</span>
					</span>
				</span>
			);
	}
}
