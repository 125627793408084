import "./Glowny.css";
import { apiUrl, mode, uploadsUrl } from "../../../globsls";

import Kalendarz from "../../components/Kalendarz";
import Zalecenia from "../../components/Glowny/Zalecenia";
import Alerty from "../../components/Glowny/Alerty";
import Diagnoza from "../../components/Glowny/Diagnoza";
import {
	Carousel,
	Col,
	Row,
	Image,
	CarouselCaption,
	Tabs,
	Tab,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import Footer from "../../Footer";

export default function KogeneracjiGlowny(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	const [slides, setSlides] = useState([[selBiogaz.plik, null]]);
	const [index, setIndex] = useState(0);
	const handleSelect = (selectedIndex) => {
		setIndex(selectedIndex);
	};
	useEffect(() => {
		let buffer = [];
		const form = new FormData();
		form.append("action", "getReklamy");
		form.append("tryb", "k");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				res.forEach((a) => {
					// buffer.push([selBiogaz.plik, null]);
					buffer.push([a.plik, a.link]);
				});
				setSlides(buffer);
				setIndex(0);
			});
	}, [selBiogaz]);
	return (
		<div className="Glowny">
			<div>
				{slides.length > 0 ? (
					<Carousel
						interval={10000}
						indicators={false}
						activeIndex={index}
						onSelect={handleSelect}
					>
						{slides.map((a, i) => (
							<Carousel.Item key={i}>
								{a[1] ? (
									<>
										<a
											href={a[1]}
											target="_BLANK"
											rel="noreferrer"
											className="slajdlink"
										>
											<Image src={uploadsUrl + a[0]}></Image>
										</a>
										<CarouselCaption>
											<a href={a[1]} className="slajdOpis">
												{/* <h3>Zapoznaj się z ofertą</h3> */}
											</a>
										</CarouselCaption>
									</>
								) : (
									<span>
										<Image src={uploadsUrl + a[0]}></Image>
									</span>
								)}
							</Carousel.Item>
						))}
					</Carousel>
				) : (
					<></>
				)}
			</div>
			<div
				style={{ marginTop: "1rem", display: "flex", justifyContent: "center" }}
			>
				<h1> Serwis kogeneracji</h1>
			</div>
			<Row>
				<Col
					style={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
						width: "60%",
						marginLeft: "2rem",
					}}
				>
					<div style={{ fontSize: "xx-large", width: "80%", textAlign: "" }}>
						<b>JEDNOSTKI KOGENERACYJNE</b>
						<br></br>
						<img
							src={"/glownyKogen.png"}
							alt="obraz"
							style={{
								width: "100%",
								height: "30rem",
								objectFit: "cover",
								justifySelf: "left",
								// marginRight: "-1.5rem",
								float: "right",
							}}
						></img>
					</div>
					<div
						style={{
							width: "100%",
							height: "3px",
							background: "#0e9dd9",
							boxShadow: "1px 1px 2px #0e9dd9",
							margin: "1rem",
						}}
					></div>
					<div
						style={{ fontSize: "x-large", width: "80%", textAlign: "justify" }}
					>
						Dysponujemy własnym laboratorium biotechnologicznym wyposażonym w
						specjalistyczny sprzęt pomiarowy i analityczny dostosowany do
						specyfiki funkcjonowania instalacji biogazowych, który pozwala
						naszym specjalistom przeprowadzić szereg badań z zakresu
						monitorowania jakości substratów, składu gazów, temperatury, pH,
						oraz aktywności mikroorganizmów.{" "}
					</div>
					<br></br> <br></br>
					<div
						style={{ fontSize: "xx-large", width: "80%", textAlign: "" }}
						className="bioTab"
					>
						<b>NAJWYŻSZA WYDAJNOŚĆ I MAKSYMALNE OCZYSZCZANIE?</b>
						<br></br>
						<div>
							System agriClean służy oczyszczaniu gazu wytwarzanego w biogazowni
							w celu wykorzystania go w jednostce kogeneracyjnej. W praktyce
							pozwala przygotować do spalania nie tylko biogaz, ale także gaz
							ściekowy i wysypiskowy. Jest przeznaczony do ciągłej pracy na
							zewnątrz, a przy stałym przepływie jest również odporny na
							zamarzanie.
						</div>
					</div>
				</Col>
				<Col style={{ width: "40%" }}>
					<div
						style={{
							fontSize: "x-large",
							width: "80%",
							textAlign: "justify",
							marginTop: "3rem",
						}}
					>
						Dostarczamy spersonalizowane rozwiązania energetyczne, bazujące na
						systemach kogeneracyjnych. Jako grupa agriKomp produkujemy
						zoptymalizowane jednostki kogeneracyjne, wykorzystujące nowoczesne
						technologie silnikowe o niskiej lub średniej mocy pochodzące od
						renomowanych producentów, takich jak SCANIA.
					</div>
					<img
						src={"/glownyKogen2.png"}
						alt="obraz"
						style={{
							width: "80%",
							height: "60rem",
							objectFit: "cover",
							marginTop: "-15rem",
							justifySelf: "left",
							marginRight: "10rem",
							float: "right",
						}}
					></img>
				</Col>
			</Row>
			<Footer></Footer>
		</div>
	);
}
