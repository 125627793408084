import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Col,
	Form,
	FormControl,
	Row,
	Table,
} from "react-bootstrap";
import "./FileSystem.css";
import { FileUploader } from "react-drag-drop-files";
import { useEffect, useRef, useState } from "react";
import { apiUrl, mode, uploadsUrl } from "../../globsls";
import SortIndicator from "./SortIndicator";

function humanFileSize(bytes, si = false, dp = 1) {
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh) {
		return bytes + " B";
	}

	const units = si
		? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
		: ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
	let u = -1;
	const r = 10 ** dp;

	do {
		bytes /= thresh;
		++u;
	} while (
		Math.round(Math.abs(bytes) * r) / r >= thresh &&
		u < units.length - 1
	);

	return bytes.toFixed(dp) + " " + units[u];
}

const handleDownload = (url, fileName) => {
	fetch(url)
		.then((response) => response.blob())
		.then((blob) => {
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement("a");
			link.href = url;
			link.download = fileName || "downloaded-file";
			document.body.appendChild(link);

			link.click();

			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		})
		.catch((error) => {
			console.error("Error fetching the file:", error);
		});
};

export default function FileSystem(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	const [sciezka, setSciezka] = useState("/");
	const [pliki, setPliki] = useState([]);
	const [loading, setLoading] = useState(false);
	const barRef = useRef(null);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getDokumenty");
		form.append("id", selBiogaz.id);
		form.append("biogazownia", selBiogaz.nazwa);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				setPliki([
					...res.map((a) => ({
						...a,
						sciezka: "/" + a.sciezka.split("/").slice(3).join("/"),
					})),
					{ sciezka: "/1. Przygotowanie Inwestycji/" },
					{ sciezka: "/1. Przygotowanie Inwestycji/1. Obliczenia/" },
					{ sciezka: "/1. Przygotowanie Inwestycji/2. WZ; MPZP; WP/" },
					{
						sciezka:
							"/1. Przygotowanie Inwestycji/3. Projekt i Pozwolenie na budowę/",
					},
					{ sciezka: "/1. Przygotowanie Inwestycji/4. Zaświadczenie URE/" },
					{ sciezka: "/1. Przygotowanie Inwestycji/5. Pozostałe/" },
					{ sciezka: "/2. Budowa/" },
					{ sciezka: "/2. Budowa/1. Dziennik budowy/" },
					{ sciezka: "/2. Budowa/2. Pozwolenia zamienne/" },
					{ sciezka: "/2. Budowa/3. Protokoły/" },
					{ sciezka: "/2. Budowa/4. Dokumenty odbiorowe/" },
					{ sciezka: "/2. Budowa/5. Gwarancje/" },
					{ sciezka: "/2. Budowa/6. Inne/" },
					{ sciezka: "/3. Serwis/" },
					{ sciezka: "/3. Serwis/1. Administracja/" },
					{ sciezka: "/3. Serwis/1. Administracja/1. KOWR/" },
					{
						sciezka:
							"/3. Serwis/1. Administracja/1. KOWR/1. Zgłoszenie do KOWR/",
					},
					{ sciezka: "/3. Serwis/1. Administracja/1. KOWR/2. Kontrole/" },
					{ sciezka: "/3. Serwis/1. Administracja/2. URE i Energetyka/" },
					{
						sciezka:
							"/3. Serwis/1. Administracja/2. URE i Energetyka/1. Zgłoszenia/",
					},
					{
						sciezka:
							"/3. Serwis/1. Administracja/2. URE i Energetyka/2. Sprawozdania/",
					},
					{
						sciezka:
							"/3. Serwis/1. Administracja/2. URE i Energetyka/3. Kontrole/",
					},
					{ sciezka: "/3. Serwis/1. Administracja/3. Weterynaria/" },
					{ sciezka: "/3. Serwis/1. Administracja/3. Weterynaria/1. HACCP/" },
					{
						sciezka:
							"/3. Serwis/1. Administracja/3. Weterynaria/2. Badania i decyzje/",
					},
					{
						sciezka: "/3. Serwis/1. Administracja/3. Weterynaria/3. Kontrole/",
					},
					{ sciezka: "/3. Serwis/1. Administracja/4. Ochrona środowiska/" },
					{
						sciezka:
							"/3. Serwis/1. Administracja/4. Ochrona środowiska/1. Przetwarzanie, wytwarzanie i magazynowanie odpadów/",
					},
					{
						sciezka:
							"/3. Serwis/1. Administracja/4. Ochrona środowiska/2. BDO/",
					},
					{
						sciezka:
							"/3. Serwis/1. Administracja/4. Ochrona środowiska/3. Kontrole/",
					},
					{ sciezka: "/3. Serwis/1. Administracja/5. Umowy serwisowe/" },
					{ sciezka: "/3. Serwis/1. Administracja/6. Pozostałe/" },
					{ sciezka: "/3. Serwis/2. Serwis biologiczny/" },
					{
						sciezka:
							"/3. Serwis/2. Serwis biologiczny/1. Badania masy fermentacyjnej/",
					},
					{ sciezka: "/3. Serwis/2. Serwis biologiczny/2. Pozostałe badania/" },
					{ sciezka: "/3. Serwis/2. Serwis biologiczny/3. Pozostałe/" },
					{ sciezka: "/3. Serwis/3. Serwis technologiczny/" },
					{
						sciezka:
							"/3. Serwis/3. Serwis technologiczny/1. Raporty serwisowe/",
					},
					{
						sciezka:
							"/3. Serwis/3. Serwis technologiczny/2. Instrukcje urządzeń technologicznych/",
					},
					{ sciezka: "/3. Serwis/3. Serwis technologiczny/3. Pozostałe/" },
					{ sciezka: "/3. Serwis/4. Serwis kogeneracji/" },
					{
						sciezka: "/3. Serwis/4. Serwis kogeneracji/1. Raporty i protokoły/",
					},
					{ sciezka: "/3. Serwis/4. Serwis kogeneracji/2. Badania oleju/" },
					{ sciezka: "/3. Serwis/4. Serwis kogeneracji/3. Instrukcje JK/" },
					{ sciezka: "/3. Serwis/4. Serwis kogeneracji/4. Pozostałe/" },
					{ sciezka: "/3. Serwis/5. Pozostałe/" },
					{ sciezka: "/4. Korespondencja/" },
					{ sciezka: "/5. Pozostałe/" },
				]);
			});
	}, [selBiogaz, refresh]);
	const [uploading, setUploading] = useState(false);
	function submitHandler(event) {
		event.preventDefault();
		setUploading(true);
		const form = new FormData(event.target);
		form.append("action", "uploadFile");
		form.append("sciezka", "/biogazowanie/" + selBiogaz.nazwa + sciezka);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				const form = new FormData();
				form.append("action", "addDokument");
				form.append("biogazownia", selBiogaz.id);
				form.append("plik", res);
				form.append("user", JSON.stringify(loggedIn));
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				})
					.then((res) => reload((a) => !a))
					.then((res) => setUploading(false));
			});
	}
	function delHandler(id) {
		setUploading(true);
		const form = new FormData();
		form.append("action", "deleteFile");
		form.append("id", id);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				console.log(res);
				if (res.status === "error") {
					window.alert(res.message);
				}
			})
			.then(() => reload((a) => !a));
	}
	function dirBack() {
		setSciezka((a) => a.substring(0, a.slice(0, -1).lastIndexOf("/") + 1));
	}
	const [sortBy, setSortBy] = useState("data_dodania"); // default sorting by 'data_dodania'
	const [sortDir, setSortDir] = useState("asc"); // ascending by default

	const handleSort = (column) => {
		if (sortBy === column) {
			setSortDir(sortDir === "asc" ? "desc" : "asc"); // toggle direction
		} else {
			setSortBy(column); // change column
			setSortDir("asc"); // reset direction
		}
	};

	return (
		<div className="FileSystem">
			<h2>Dokumenty</h2>
			<Row className="path">
				<Col md="auto" style={{ fontSize: "1.25em" }}>
					<Breadcrumb>
						<BreadcrumbItem
							onClick={() => setSciezka("/")}
							className="linkBreadcrumb"
						>
							dokumenty
						</BreadcrumbItem>
						{sciezka
							.split("/")
							.filter((a) => a !== "")
							.map((a, i) => {
								return (
									<BreadcrumbItem
										onClick={() =>
											setSciezka(
												"/" +
													sciezka
														.split("/")
														.filter((a) => a !== "")
														.splice(0, i + 1)
														.join("/") +
													"/"
											)
										}
										className="linkBreadcrumb"
									>
										{a}
									</BreadcrumbItem>
								);
							})}
					</Breadcrumb>
				</Col>
				{/* <Col md="auto">
					<FormControl
						type="text"
						size="sm"
						md="auto"
						style={{
							border: "none",
							width: "100px",
							borderBottom: "1px solid black",
							borderRadius: "0px",
							boxShadow: "none",
						}}
						ref={barRef}
					></FormControl>
				</Col> */}
				{/* <Col>
					<Button
						variant="primary"
						size="sm"
						onClick={() =>
							setSciezka(
								(a) =>
									a +
									barRef.current.value +
									(barRef.current.value.slice(-1) !== "/" ? "/" : "")
							)
						}
					>
						{">"}
					</Button>
				</Col> */}
			</Row>

			{/* Sorting State */}
			{(() => {
				const sortedFiles = [...pliki]
					.filter((file) => file.sciezka === sciezka)
					.sort((a, b) => {
						if (sortBy === "nazwa") {
							if (!a.nazwa) {
								return sortDir === "asc"
									? a.sciezka.localeCompare(b.sciezka)
									: b.sciezka.localeCompare(a.sciezka);
							} else {
								return sortDir === "asc"
									? a.nazwa.localeCompare(b.nazwa)
									: b.nazwa.localeCompare(a.nazwa);
							}
						} else if (sortBy === "data_dodania") {
							return sortDir === "asc"
								? new Date(a.data_dodania) - new Date(b.data_dodania)
								: new Date(b.data_dodania) - new Date(a.data_dodania);
						} else if (sortBy === "rozmiar") {
							return sortDir === "asc"
								? a.rozmiar - b.rozmiar
								: b.rozmiar - a.rozmiar;
						}
						return 0;
					});

				return (
					<>
						<Table bordered className="fileSystemTable" hover striped>
							<thead style={{ userSelect: "none" }}>
								<tr>
									<th
										onClick={() => handleSort("nazwa")}
										style={{ cursor: "pointer" }}
									>
										Nazwa
										<span className="sort-icons">
											<SortIndicator
												mode={
													sortBy === "nazwa"
														? sortDir === "asc"
															? "asc"
															: "desc"
														: ""
												}
											></SortIndicator>
										</span>
									</th>
									<th
										onClick={() => handleSort("data_dodania")}
										style={{ cursor: "pointer" }}
									>
										Data dodania
										<SortIndicator
											mode={
												sortBy === "data_dodania"
													? sortDir === "asc"
														? "asc"
														: "desc"
													: ""
											}
										></SortIndicator>
									</th>
									<th
										onClick={() => handleSort("rozmiar")}
										style={{ cursor: "pointer" }}
									>
										Rozmiar
										<span className="sort-icons">
											<SortIndicator
												mode={
													sortBy === "rozmiar"
														? sortDir === "asc"
															? "asc"
															: "desc"
														: ""
												}
											></SortIndicator>
										</span>
									</th>
									<th style={{ width: loggedIn !== 3 ? "200px" : "100px" }}>
										Czynności{" "}
										<span style={{ fontSize: "x-small" }}>(do zrobienia)</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{sciezka !== "/" && (
									<tr>
										<th colSpan={10} onClick={() => dirBack()}>
											..
										</th>
									</tr>
								)}

								{[...new Set(pliki.map((a) => a.sciezka))]
									.filter((b) => b.startsWith(sciezka) && b !== sciezka)
									.map((d) => d.substring(sciezka.length))
									.map((e) => e.substring(0, e.indexOf("/") + 1))
									.filter(function (item, pos, self) {
										return self.indexOf(item) === pos;
									})
									.sort((a, b) => a.localeCompare(b))
									.map((c, i) => (
										<tr key={i}>
											<th colSpan={10} onClick={() => setSciezka(sciezka + c)}>
												<span style={{ display: "flex", alignItems: "center" }}>
													<span className="material-symbols-outlined">
														folder
													</span>
													<span style={{ fontSize: "20px", marginLeft: "4px" }}>
														{c.slice(0, -1)}
													</span>
												</span>
											</th>
										</tr>
									))}

								{/* Only sorted files are displayed here */}
								{sortedFiles
									.filter((c) => c.plik)
									.map((a, i) => (
										<tr key={i}>
											<th>
												<a
													rel="noreferrer"
													target="_blank"
													href={uploadsUrl + a.plik}
													onClick={() => window.open(uploadsUrl + a.plik)}
												>
													{a.nazwa}
												</a>
											</th>
											<th>{a.data_dodania}</th>
											<th>{humanFileSize(a.rozmiar, true)}</th>

											<th>
												<span
													style={{
														display: "flex",
														justifyContent: "space-around",
													}}
												>
													{loggedIn.typ !== "3" && (
														<Button
															size="sm"
															variant="link"
															className="fsDelButton"
															style={{ margin: "0px", padding: "0px" }}
															onClick={() => delHandler(a.id)}
														>
															Usuń
														</Button>
													)}
													{loggedIn.typ !== "3" && (
														<Button
															size="sm"
															variant="link"
															style={{
																color: "black",
																textWrap: "nowrap",
																margin: "0px",
																padding: "0px",
															}}
														>
															Zmień nazwę
														</Button>
													)}
													<Button
														size="sm"
														variant="link"
														style={{ margin: "0px", padding: "0px" }}
														onClick={() =>
															handleDownload(uploadsUrl + a.plik, a.nazwa)
														}
													>
														Pobierz
													</Button>
												</span>
											</th>
										</tr>
									))}
								{loading && <tr></tr>}
							</tbody>
						</Table>

						{loggedIn.typ !== "3" && (
							<Form onSubmit={submitHandler}>
								<Row>
									<Col>
										<FormControl type="file" name="plik"></FormControl>
									</Col>
									<Col md="auto">
										<Button
											type="submit"
											disabled={uploading}
											style={{ marginTop: "0px" }}
										>
											{uploading ? "Przesyłanie..." : "Wyślij"}
										</Button>
									</Col>
								</Row>
							</Form>
						)}
					</>
				);
			})()}
		</div>
	);
}
