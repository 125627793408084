import "./OpcjeKonta.css";
import { uploadsUrl, mode, apiUrl } from "../globsls";
import { useEffect, useState, useRef } from "react";
import {
	Button,
	Col,
	Form,
	FormControl,
	FormGroup,
	FormLabel,
	FormSelect,
	Modal,
	ModalBody,
	ModalHeader,
	Overlay,
	Row,
	Table,
	Image,
	Tooltip,
	ModalFooter,
} from "react-bootstrap";

export default function OpcjeKonta(props) {
	const { dane, reload, refresh, setLoggedIn, uprawnienia, loggedIn } = props;
	const [show, setShow] = useState(false);
	const [konta, setKonta] = useState([]);
	const typy = {
		1: "Administrator",
		2: "Serwis",
		21: "Serwis biologiczny",
		22: "Serwis technologiczny",
		23: "Serwis kogeneracji",
		3: "Inwestor",
	};
	const [obraz, setObraz] = useState(false);
	const target = useRef(null);
	const [zmien, setZmien] = useState(false);
	const [uploading, setUploading] = useState(false);
	const nazwaRef = useRef(null);
	const plikRef = useRef(null);
	const emailRef = useRef(null);
	const telRef = useRef(null);
	const typRef = useRef(null);
	const hasloRef = useRef(null);
	console.log(loggedIn);
	function submitHandler() {
		if (zmien) {
			setUploading(true);
			const form = new FormData();
			form.append("action", "uploadFile");
			form.append("sciezka", "/banery/");
			form.append("flagPreciseResponse", "true");
			form.append("plik", plikRef.current.files[0]);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => {
					const form = new FormData();
					form.append("action", "updateKonto");
					form.append("nazwa", nazwaRef.current.value);
					form.append("typ", loggedIn.typ);
					form.append("email", emailRef.current.value);
					form.append("tel", telRef.current.value);
					form.append("haslo", hasloRef.current.value);
					form.append("nazwa", nazwaRef.current.value);
					form.append("obraz", res.id);
					form.append("id", dane.id);
					fetch(apiUrl, {
						mode: mode,
						method: "POST",
						body: form,
					})
						.then((res2) => res2.text())
						.then((res2) => console.log(res))
						.then(() => {
							document.cookie =
								"loggedIn=; expires=Thu, 18 Dec 2013 12:00:00 UTC";
							setLoggedIn(false);
							// setLoggedIn((a) => ({ ...a, obraz: res.plik }));
							setShow(false);
							setUploading(false);
							reload((a) => !a);
						});
				});
		} else {
			const form = new FormData();
			form.append("action", "updateKonto");
			form.append("nazwa", nazwaRef.current.value);
			form.append("typ", loggedIn.typ);
			form.append("tel", telRef.current.value);
			form.append("email", emailRef.current.value);
			form.append("haslo", hasloRef.current.value);
			form.append("nazwa", nazwaRef.current.value);
			form.append("id", dane.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then(() => reload((a) => !a))
				.then(() => setShow(false));
			document.cookie = "loggedIn=; expires=Thu, 18 Dec 2013 12:00:00 UTC";
			setLoggedIn(false);
		}
	}
	return (
		<div className="OpcjeKonta">
			<Form>
				<FormGroup>
					<FormLabel>Nazwa</FormLabel>
					<FormControl
						name="nazwa"
						type="text"
						ref={nazwaRef}
						placeholder="nazwa konta"
						defaultValue={dane.nazwa}
					></FormControl>
				</FormGroup>
				<FormGroup style={{ color: "red" }}>
					<FormLabel>Hasło (zostaw puste by nie zmieniać!)</FormLabel>
					<FormControl
						style={{ backgroundColor: "#ff000021" }}
						name="haslo"
						type="text"
						ref={hasloRef}
						placeholder="haslo konta (zostaw puste by nie zmieniać!)"
					></FormControl>
				</FormGroup>
				<FormGroup>
					<FormLabel>Email</FormLabel>
					<FormControl
						name="email"
						type="text"
						ref={emailRef}
						placeholder="email konta"
						defaultValue={dane.email}
					></FormControl>
				</FormGroup>
				<FormGroup>
					<FormLabel>Nr Tel</FormLabel>
					<FormControl
						name="tel"
						type="text"
						ref={telRef}
						placeholder="Numer telefonu"
						defaultValue={dane.tel}
					></FormControl>
				</FormGroup>
				{uprawnienia && (
					<FormGroup>
						<FormLabel>Typ konta</FormLabel>
						<FormSelect
							name="typ"
							type="text"
							ref={typRef}
							defaultValue={dane.typ}
						>
							<option value="1">Admin</option>
							<option value="3">Inwestor</option>
							<option value="21">Serwis biologiczny</option>
							<option value="22">Serwis technologiczny</option>
							<option value="23">Serwis kogeneracji</option>
						</FormSelect>
					</FormGroup>
				)}
				<FormGroup>
					<FormLabel>Zdjęcie</FormLabel>
					<Row>
						<Col md="auto">
							<Button
								onClick={() => setZmien((a) => !a)}
								style={{ marginTop: "0.0rem", marginRight: "0px" }}
							>
								{zmien ? "Anuluj zmianę" : "Zmień zdjęcie"}
							</Button>
						</Col>
						<Col hidden={!zmien} style={{ margin: "0px", padding: "0px" }}>
							<FormControl
								type="file"
								name="plik"
								ref={plikRef}
								style={{ width: "100%", marginLeft: "12px" }}
							></FormControl>
						</Col>
					</Row>
				</FormGroup>
				<Button onClick={() => submitHandler()} disabled={uploading}>
					Zapisz
				</Button>
			</Form>
		</div>
	);
}
