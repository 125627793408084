import { useEffect, useRef, useState } from "react";
import { apiUrl, mode } from "../../globsls";
import {
	Button,
	Col,
	Row,
	Table,
	Modal,
	ModalBody,
	ModalHeader,
	Form,
	FormGroup,
	FormLabel,
	FormControl,
	FormSelect,
	ModalFooter,
} from "react-bootstrap";
import "./Czesci.css";
import ReactSelect from "react-select";
import { InstrukcjeForm } from "./Instrukcje";

export default function Czesci(props) {
	const { selBiogaz, loggedIn, reload, refresh, typ, uprawnienia, selBiogaz2 } =
		props;
	return (
		<div className="Czesci">
			{typ === "technologiczny" ? (
				<CzesciTech
					selBiogaz={selBiogaz}
					loggedIn={loggedIn}
					reload={reload}
					refresh={refresh}
					uprawnienia={uprawnienia}
				></CzesciTech>
			) : (
				<CzesciKogen
					selBiogaz={selBiogaz}
					loggedIn={loggedIn}
					reload={reload}
					refresh={refresh}
					uprawnienia={uprawnienia}
					selBiogaz2={selBiogaz2}
				></CzesciKogen>
			)}
		</div>
	);
}
function CzesciTech(props) {
	const { selBiogaz, loggedIn, reload, refresh, uprawnienia } = props;
	const [czesci, setCzesci] = useState([]);
	const [czesciAll, setCzesciAll] = useState([]);
	const [buffer, setBuffer] = useState([]);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getCzesci");
		selBiogaz && form.append("id", selBiogaz.id);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				setCzesci(
					Object.values(Object.groupBy(res, ({ kategoria }) => kategoria)).map(
						(a) => Object.values(Object.groupBy(a, ({ typ }) => typ))
					)
				);
			});
		if (uprawnienia) {
			const form = new FormData();
			form.append("action", "getCzesci");
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => setCzesciAll(res));
		}
	}, [refresh]);
	useEffect(() => {
		if (czesciAll.length > 0) {
			let buffer3 = [...new Set(czesciAll.map((a) => a.kategoria))].filter(
				(a) => !czesci.map((a) => a[0][0].kategoria).includes(a)
			);
			let buffer2 = [...czesci];
			let buffer4 = buffer2.push(
				buffer3.map((a) => [
					{
						kategoria: a,
						typ: "",
						nazwa: "brak części w jednostce",
						id: "-1",
					},
				])
			);
			setBuffer(buffer2);
		}
	}, [czesciAll, czesci]);
	return (
		<Table bordered hover striped>
			<thead>
				<tr className="tableHeader">
					<th>Element biogazowni</th>
					<th>Numer aK</th>
					<th>Nazwa</th>
					{loggedIn.typ !== "3" && <th>Producent</th>}
					<th>Specyfikacja</th>
					{/* <th>Uwagi</th> */}
					<th hidden={!selBiogaz}>Ilość</th>
					<th>Zamów</th>
					{uprawnienia && <th style={{ width: "160px" }}>Opcje</th>}
				</tr>
			</thead>
			<tbody>
				{uprawnienia && buffer.length > 0 ? (
					<>
						{buffer.map((a, i) => (
							<CzesciKat
								uprawnienia={uprawnienia}
								selBiogaz={selBiogaz}
								i={i}
								reload={reload}
								a={a}
								key={i}
								czesciAll={czesciAll}
							></CzesciKat>
						))}
					</>
				) : (
					<>
						{czesci.map((a, i) => (
							<CzesciKat
								uprawnienia={uprawnienia}
								selBiogaz={selBiogaz}
								i={i}
								reload={reload}
								a={a}
								key={i}
								czesciAll={czesciAll}
								loggedIn={loggedIn}
							></CzesciKat>
						))}
					</>
				)}
			</tbody>
		</Table>
	);
}
function CzesciKat({
	uprawnienia,
	a,
	i,
	selBiogaz,
	reload,
	kogen,
	czesciAll,
	loggedIn,
}) {
	const [zwin, setZwin] = useState(false);
	useEffect(() => {
		if (kogen && a.length > 0) {
			if (a[0][0].kategoria === "wszystkie") {
				setZwin(true);
			}
		}
	}, []);
	function addToKogen(event) {
		if (kogen) {
			const form = new FormData();
			form.append("action", "addToKogen");
			form.append("id", a[0][0].jednostka);
			form.append("czesc", event.value);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then(() => reload((a) => !a));
		} else {
			const form = new FormData();
			form.append("action", "addToBiogaz");
			form.append("id", selBiogaz.id);
			form.append("czesc", event.value);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then(() => {
					reload((a) => !a);
				});
		}
	}
	function filterArrayById(am, ae) {
		if (kogen)
			if (am) {
				return am.filter((aray) => !ae.includes(aray.id));
			} else {
				return czesciAll;
			}
		else {
			if (am && am[0] !== -1) {
				return czesciAll
					.filter((wer) => wer.kategoria === a[0][0].kategoria)
					.filter((aray) => !ae.includes(aray.id));
			} else {
				return czesciAll.filter((wer) => (wer.kategoria = a[0][0].kategoria));
			}
		}
	}
	if (a.length > 0)
		return (
			<>
				<tr key={i}>
					{a[0][0].id !== -1 && (
						<th
							colSpan={100}
							style={{ backgroundColor: "var(--bs-light-border-subtle)" }}
						>
							{a[0][0].kategoria}
							<Button
								style={{ float: "right", width: 25, height: 25, padding: 0 }}
								size="sm"
								variant="outline-secondary"
								onClick={() => setZwin((a) => !a)}
							>
								{!zwin ? "+" : "-"}
							</Button>
						</th>
					)}
				</tr>
				{zwin &&
					a[0][0].id !== -1 &&
					a.map((b) =>
						b.map((c, i) => (
							<CzescRow
								uprawnienia={uprawnienia}
								c={c}
								b={b}
								selBiogaz={selBiogaz}
								i={i}
								reload={reload}
								kogen={kogen}
								loggedIn={loggedIn}
							></CzescRow>
						))
					)}
				{zwin &&
					((kogen && uprawnienia && a[0][0].jednostka) ||
						(uprawnienia && selBiogaz)) && (
						<tr>
							<td colSpan={100}>
								dodaj część do {selBiogaz ? "biogazowni" : "jednostki"}
								<ReactSelect
									onChange={(event) => addToKogen(event)}
									options={filterArrayById(
										czesciAll,
										kogen
											? a.map((tta) => tta[0].id)
											: a[0].map((qwe) => qwe.id)
									).map((idk) => ({
										value: idk.id,
										label:
											idk.nazwa ||
											idk.typ.match(/\b(\w)/g).join("") + idk.specyfikacja,
									}))}
								></ReactSelect>
							</td>
						</tr>
					)}
			</>
		);
}
function CzescRow({
	uprawnienia,
	c,
	b,
	i,
	selBiogaz,
	reload,
	kogen,
	loggedIn,
}) {
	const [show, setShow] = useState(false);
	const [producenci, setProducenci] = useState([]);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getProducenci");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setProducenci(res));
	}, []);

	function submitHandler() {
		const form = new FormData();
		form.append("action", "updateCzesc");
		form.append("id", c.id);
		form.append("ak", nakRef.current.value);
		form.append("nazwa", nazwaRef.current.value);
		form.append("sklep", linkRef.current.value);
		form.append("producent", producentRef.current.value);
		form.append("specyfikacja", specRef.current.value);
		form.append("uwagi", uwagiRef.current.value);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.text())
			.then(() => {
				setShow(false);
				reload((a) => !a);
			});
	}
	const nakRef = useRef(null);
	const nazwaRef = useRef(null);
	const producentRef = useRef(null);
	const specRef = useRef(null);
	const uwagiRef = useRef(null);
	const linkRef = useRef(null);

	function delHandler() {
		if (!c.jednostka && !selBiogaz) {
			if (
				window.confirm(
					`Czy na pewno chcesz usunąć część ${
						c.nazwa || c.typ.match(/\b(\w)/g).join("") + c.specyfikacja
					}? Ta zmiana jest nieodwracalna!`
				)
			) {
				const form = new FormData();
				form.append("action", "delCzesc");
				form.append("id", c.id);
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				})
					.then((res) => res.text())
					.then(() => reload((a) => !a));
			}
		} else {
			if (selBiogaz) {
				if (
					window.confirm(
						`Czy na pewno chcesz usunąć część ${
							c.nazwa || c.typ.match(/\b(\w)/g).join("") + c.specyfikacja
						} z biogazowni ${selBiogaz.nazwa}? Ta zmiana jest nieodwracalna!`
					)
				) {
					const form = new FormData();
					form.append("action", "delCzescZ");
					form.append("id", c.id);
					form.append("biogazownia", selBiogaz.id);
					fetch(apiUrl, {
						mode: mode,
						method: "POST",
						body: form,
					})
						.then((res) => res.text())
						.then(() => reload((a) => !a));
				}
			} else if (c.jednostka) {
				if (
					window.confirm(
						`Czy na pewno chcesz usunąć część ${
							c.nazwa || c.typ.match(/\b(\w)/g).join("") + c.specyfikacja
						} z jednostki? Ta zmiana jest nieodwracalna!`
					)
				) {
					const form = new FormData();
					form.append("action", "delCzescZ");
					form.append("id", c.id);
					form.append("jednostka", c.jednostka);
					fetch(apiUrl, {
						mode: mode,
						method: "POST",
						body: form,
					})
						.then((res) => res.text())
						.then(() => reload((a) => !a));
				}
			}
		}
	}
	function changeAmount(value) {
		if (selBiogaz) {
			const form = new FormData();
			form.append("action", "changeIloscCzesci");
			form.append("biogazownia", selBiogaz.id);
			form.append("id", c.id);
			form.append("ilosc", value);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then(() => reload((a) => !a));
		} else if (c.jednostka) {
			const form = new FormData();
			form.append("action", "changeIloscCzesci");
			form.append("jednostka", c.jednostka);
			form.append("id", c.id);
			form.append("ilosc", value);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then(() => reload((a) => !a));
		}
	}
	if (c.id !== "-1")
		return (
			<tr className="czesc" key={i}>
				{i === 0 && <th rowSpan={b.length}>{c.typ}</th>}
				<th>{c.ak}</th>
				<th>{c.nazwa || c.typ.match(/\b(\w)/g).join("") + c.specyfikacja}</th>
				{uprawnienia && <th>{c.producent}</th>}
				<th>{c.specyfikacja}</th>
				{/* <th>{c.uwagi}</th> */}
				{!kogen &&
					selBiogaz &&
					(uprawnienia ? (
						<th>
							<FormControl
								type="number"
								defaultValue={c.ilosc}
								onChange={(event) => changeAmount(event.target.value)}
							></FormControl>
						</th>
					) : (
						<th>{c.ilosc}</th>
					))}
				<th>
					<a href={c.sklep} target="_BLANK" rel="noreferrer">
						sklep
					</a>
				</th>
				{kogen &&
					(uprawnienia && c.jednostka ? (
						<th>
							<FormControl
								type="number"
								defaultValue={c.ilosc}
								onChange={(event) => changeAmount(event.target.value)}
							></FormControl>
						</th>
					) : (
						<th>{c.ilosc}</th>
					))}
				{uprawnienia && (
					<td>
						<Row>
							<Col md={5}>
								<Button size="sm" onClick={() => setShow(true)}>
									Edytuj
								</Button>
							</Col>
							<Col md={5}>
								<Button size="sm" variant="danger" onClick={() => delHandler()}>
									Usuń
								</Button>
							</Col>
						</Row>
					</td>
				)}
				<Modal show={show} onHide={() => setShow(false)}>
					<ModalHeader>
						Edycja części{" "}
						{c.nazwa || c.typ.match(/\b(\w)/g).join("") + c.specyfikacja}
					</ModalHeader>
					<ModalBody hidden={selBiogaz || c.jednostka}>
						<Form onSubmit={submitHandler}>
							<Row>
								<FormGroup as={Col}>
									<FormLabel>Numer aK</FormLabel>
									<FormControl
										placeholder="numer ak"
										defaultValue={c.ak}
										ref={nakRef}
									></FormControl>
								</FormGroup>
								<FormGroup as={Col}>
									<FormLabel>Nazwa</FormLabel>
									<FormControl
										placeholder="nazwa"
										defaultValue={c.nazwa}
										ref={nazwaRef}
									></FormControl>
								</FormGroup>
								<FormGroup as={Col}>
									<FormLabel>Producent</FormLabel>
									<FormSelect
										name="producent"
										defaultValue={c.producent}
										ref={producentRef}
									>
										{producenci.map((a, ii) => (
											<option
												key={ii}
												value={a.id}
												defaultValue={c.producentid}
											>
												{a.nazwa}
											</option>
										))}
									</FormSelect>
								</FormGroup>
							</Row>
							<Row>
								<FormGroup as={Col}>
									<FormLabel>Specyfikacja</FormLabel>
									<FormControl
										ref={specRef}
										name="specyfikacja"
										placeholder="specyfikacja"
										defaultValue={c.specyfikacja}
									></FormControl>
								</FormGroup>
								<FormGroup as={Col}>
									<FormLabel>Uwagi</FormLabel>
									<FormControl
										ref={uwagiRef}
										name="uwagi"
										placeholder="uwagi"
										defaultValue={c.uwagi}
									></FormControl>
								</FormGroup>
							</Row>
							<Row>
								<FormGroup as={Col}>
									<FormLabel>Link do sklepu</FormLabel>
									<FormControl
										ref={linkRef}
										name="sklep"
										placeholder="link do sklepu"
										defaultValue={c.sklep}
									></FormControl>
								</FormGroup>
							</Row>
						</Form>
					</ModalBody>
					<Modal.Footer hidden={selBiogaz || c.jednostka}>
						<Button variant="secondary" onClick={() => setShow(false)}>
							Anuluj
						</Button>
						<Button variant="primary" onClick={() => submitHandler()}>
							Zapisz zmiany
						</Button>
					</Modal.Footer>
					<ModalFooter>
						<InstrukcjeForm
							reload={reload}
							insmode={kogen ? "k" : "t"}
							selected={["c", c.id]}
						></InstrukcjeForm>
						<Button variant="secondary" onClick={() => setShow(false)}>
							Zamknij
						</Button>
					</ModalFooter>
				</Modal>
			</tr>
		);
}
function CzesciKogen(props) {
	const { selBiogaz, loggedIn, reload, refresh, uprawnienia } = props;
	const [czesci, setCzesci] = useState([]);
	const [czesciAll, setCzesciAll] = useState([]);
	const [buffer, setBuffer] = useState([]);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getCzesciJk");
		selBiogaz && form.append("id", selBiogaz.id);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				setCzesciAll(
					res
						.map((a) => ({
							...a,
							jednostka: null,
							ilosc: null,
							kategoria: "wszystkie",
						}))
						.filter(
							(item, index, self) =>
								index === self.findIndex((t) => t.id === item.id)
						)
				);
				setCzesci(
					Object.values(
						Object.groupBy(
							res.filter((c) => c.jednostka),
							({ kategoria }) => kategoria
						)
					).map((a) => Object.values(Object.groupBy(a, ({ typ }) => typ)))
				);
			});
	}, [refresh]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (!selBiogaz) {
			const form = new FormData();
			form.append("action", "getJednostkiBez");
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => {
					let buffer2 = [...czesci];
					if (res.length > 0) {
						res.forEach((a) => {
							if (a) {
								buffer2.push([
									[
										{
											jednostka: a.id,
											kategoria: a.nazwa + " (bez części)",
											typ: "",
											nazwa: "brak części w jednostce",
											id: "-1",
										},
									],
								]);
							}
						});
					}
					let buffer3 = [...buffer2];
					buffer3.push(czesciAll.map((a) => [a]));
					if (buffer3.length > 1) {
						if (buffer3[1].length !== 0) {
							setBuffer(buffer3);
							setLoading(false);
						}
					}
				});
		}
	}, [czesciAll, czesci]);
	if (!selBiogaz) {
		if (!loading && buffer.length > 0 && buffer[0].length > 0) {
			return (
				<Table bordered hover striped>
					<thead>
						<tr className="tableHeader">
							<th>Element biogazowni</th>
							<th>Numer aK</th>
							<th>Nazwa</th>
							{loggedIn.typ !== "3" && <th>Producent</th>}

							<th>Specyfikacja</th>
							{/* <th>Uwagi</th> */}
							<th>Zamów</th>
							<th>Ilość</th>
							{uprawnienia && <th style={{ width: "160px" }}>Opcje</th>}
						</tr>
					</thead>
					<tbody>
						{buffer.map((a, i) => (
							<CzesciKat
								uprawnienia={uprawnienia}
								selBiogaz={selBiogaz}
								i={i}
								reload={reload}
								a={a}
								key={i}
								kogen
								czesciAll={czesciAll}
							></CzesciKat>
						))}
					</tbody>
				</Table>
			);
		}
	} else {
		return (
			<Table bordered hover striped>
				<thead>
					<tr className="tableHeader">
						<th>Element biogazowni</th>
						<th>Numer aK</th>
						<th>Nazwa</th>
						{loggedIn.typ !== "3" && <th>Producent</th>}
						<th>Specyfikacja</th>
						{/* <th>Uwagi</th> */}
						<th>Zamów</th>
						<th>Ilość</th>
						{uprawnienia && <th style={{ width: "160px" }}>Opcje</th>}
					</tr>
				</thead>
				<tbody>
					{czesci.map((a, i) => (
						<CzesciKat
							uprawnienia={uprawnienia}
							selBiogaz={selBiogaz}
							i={i}
							reload={reload}
							a={a}
							key={i}
							kogen
							czesciAll={czesciAll}
						></CzesciKat>
					))}
				</tbody>
			</Table>
		);
	}
}
export function JkForm(props) {
	const { selBiogaz, loggedIn, reload, refresh, uprawnienia } = props;
	const [jednostki, setJednostki] = useState([]);
	const [jednostkiBiogazu, setJednostkiBiogazu] = useState([]);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getAllJk");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setJednostki(res));
		form.append("id", selBiogaz.id);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setJednostkiBiogazu(res));
	}, [selBiogaz, refresh]);
	const selectRef = useRef("null");
	function submitHandler() {
		const form = new FormData();
		form.append("action", "przypiszJk");
		form.append("id", selBiogaz.id);
		form.append("czesci", JSON.stringify(selectRef.current.getValue()));
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.text())
			.then(() => reload((a) => !a));
	}
	if (loggedIn.typ < "3") {
		return (
			<div>
				<h4>Przypisz jednostki kogeneracyjne</h4>
				<ReactSelect
					ref={selectRef}
					isMulti
					defaultValue={[
						...jednostkiBiogazu.map((a) => ({ value: a.id, label: a.nazwa })),
					]}
					options={jednostki.map((a) => ({ value: a.id, label: a.nazwa }))}
				></ReactSelect>
				<Button onClick={() => submitHandler()}>Zatwierdź</Button>
			</div>
		);
	}
}
