import "./AdminBiogazownie.css";
import { mode, apiUrl, uploadsUrl } from "../../../globsls";
import { useEffect, useState, useRef } from "react";
import {
	Button,
	Col,
	Form,
	FormControl,
	FormGroup,
	FormLabel,
	FormSelect,
	Modal,
	ModalBody,
	ModalHeader,
	Overlay,
	Row,
	Table,
	Image,
	Tooltip,
	ModalFooter,
} from "react-bootstrap";
import { createPortal } from "react-dom";
import ReactSelect from "react-select";

export default function AdminBiogazownie(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	const [biogazownie, setBiogazownie] = useState([]);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getBiogazownie");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setBiogazownie(res));
	}, [loggedIn, refresh]);

	return (
		<div className="AdminBiogazownie">
			<h4>Biogazownie</h4>
			<Table bordered striped hover>
				<thead>
					<tr>
						<th>L.p</th>
						<th>Nazwa</th>
						<th>Posiadacz</th>
						<th>
							Reklamy <sub>przestarzałe</sub>
						</th>
						<th>Baner</th>
						<th>Opcje</th>
					</tr>
				</thead>
				<tbody>
					{biogazownie.map((a, i) => (
						<BiogazRow key={i} dane={a} reload={reload}></BiogazRow>
					))}
				</tbody>
			</Table>
			<BiogazForm reload={reload}></BiogazForm>
		</div>
	);
}
function BiogazRow({ dane, reload }) {
	const [show, setShow] = useState(false);
	const [konta, setKonta] = useState([]);

	useEffect(() => {
		const form = new FormData();
		form.append("action", "getKonta");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setKonta(res));
	}, []);
	function delHandler() {
		if (
			window.confirm(
				`Czy na pewno chcesz usunąć biogazownię ${dane.nazwa}? Ta zmiana jest nieodwracalna!`
			)
		) {
			const form = new FormData();
			form.append("action", "delBiogazownia");
			form.append("id", dane.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => reload((a) => !a));
		}
	}
	const [obraz, setObraz] = useState(false);
	const target = useRef(null);
	const [zmien, setZmien] = useState(false);
	const nazwaRef = useRef(null);
	const posiadaczRef = useRef(null);
	const plikRef = useRef(null);
	const reklamyRef = useRef(null);
	const [uploading, setUploading] = useState(false);
	function submitHandler() {
		if (zmien) {
			const form = new FormData();
			setUploading(true);
			form.append("action", "uploadFile");
			form.append("sciezka", "/banery/");
			form.append("plik", plikRef.current.files[0]);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => {
					const form = new FormData();
					form.append("action", "updateBiogazownia");
					form.append("nazwa", nazwaRef.current.value);
					form.append("posiadacz", posiadaczRef.current.value);
					form.append("obraz", res);
					form.append(
						"reklamy",
						reklamyRef.current
							.getValue()
							.map((a) => a.value)
							.join("")
					);
					form.append("id", dane.id);
					fetch(apiUrl, {
						mode: mode,
						method: "POST",
						body: form,
					})
						.then((res) => res.text())
						.then((res) => console.log(res))
						.then(() => reload((a) => !a))
						.then(() => {
							setShow(false);
							setUploading(false);
						});
				});
		} else {
			const form = new FormData();
			form.append("action", "updateBiogazownia");
			form.append("nazwa", nazwaRef.current.value);
			form.append("posiadacz", posiadaczRef.current.value);
			form.append(
				"reklamy",
				reklamyRef.current
					.getValue()
					.map((a) => a.value)
					.join("")
			);
			form.append("id", dane.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => reload((a) => !a))
				.then(() => setShow(false));
		}
	}
	const typy = {
		b: "biologiczny",
		t: "technologiczny",
		k: "kogeneracji",
		o: "ogólne",
		s: "specjalne",
	};
	return (
		<>
			<tr>
				<td>{dane.id}</td>
				<td>{dane.nazwa}</td>
				<td>{dane.posnazwa}</td>
				<td>
					{dane.reklamy
						? dane.reklamy.split("").map((a) => (
								<>
									<div style={{ fontSize: "x-small" }}>{typy[a]}</div>
								</>
							))
						: ""}
				</td>
				<td
					onMouseEnter={() => setObraz(true)}
					onMouseLeave={() => setObraz(false)}
				>
					<a
						ref={target}
						href={uploadsUrl + dane.plik}
						target="_BLANK"
						rel="noreferrer"
					>
						{dane.plik}
					</a>
					<Overlay target={target.current} show={obraz} placement="bottom">
						{(props) => (
							<div {...props}>
								<Image
									rounded
									style={{ maxWidth: "400px", maxHeight: "300px" }}
									src={uploadsUrl + dane.plik}
								></Image>
							</div>
						)}
					</Overlay>
				</td>
				<td style={{ width: "180px" }}>
					<Row>
						<Col>
							<Button variant="primary" size="sm" onClick={() => setShow(true)}>
								Edytuj
							</Button>
						</Col>
						<Col>
							<Button variant="danger" size="sm" onClick={() => delHandler()}>
								Usuń
							</Button>
						</Col>
					</Row>
				</td>
			</tr>
			<Modal show={show} onHide={() => setShow(false)}>
				<ModalHeader>
					Edycja biogazowni {dane.id} ({dane.nazwa})
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup>
							<FormLabel>Nazwa</FormLabel>
							<FormControl
								name="nazwa"
								type="text"
								ref={nazwaRef}
								placeholder="nazwa biogazowni"
								defaultValue={dane.nazwa}
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>Posiadacz</FormLabel>
							<FormSelect
								defaultValue={dane.posiadacz}
								name="posiadacz"
								ref={posiadaczRef}
							>
								<option value="" style={{ color: "gray" }}>
									--brak--
								</option>
								{konta
									.filter((a) => a.typ === "3")
									.map((a, i) => (
										<option key={i} value={a.id}>
											{a.nazwa}
										</option>
									))}
							</FormSelect>
						</FormGroup>
						<FormGroup>
							<FormLabel>
								Reklamy <sub>przestarzałe</sub>
							</FormLabel>
							<ReactSelect
								defaultValue={
									Array.isArray(dane.reklamy)
										? dane.reklamy.map((a) => ({ value: a, label: typy[a] }))
										: []
								}
								options={Object.keys(typy).map((a) => ({
									value: a,
									label: typy[a],
								}))}
								isMulti
								isClearable
								closeMenuOnSelect={false}
								ref={reklamyRef}
							></ReactSelect>
						</FormGroup>
						<FormGroup>
							<FormLabel>Baner</FormLabel>
							<Row>
								<Col md="auto">
									<Button onClick={() => setZmien((a) => !a)}>
										{zmien ? "Anuluj zmianę" : "Zmień baner"}
									</Button>
								</Col>
								<Col hidden={!zmien}>
									<FormControl
										type="file"
										name="plik"
										ref={plikRef}
									></FormControl>
								</Col>
							</Row>
						</FormGroup>
					</Form>
				</ModalBody>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Anuluj
					</Button>
					<Button
						variant="primary"
						onClick={() => submitHandler()}
						disabled={uploading}
					>
						{uploading ? "Przesyłanie..." : "Dodaj"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
function BiogazForm({ reload }) {
	const [konta, setKonta] = useState([]);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getKonta");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setKonta(res));
	}, []);
	const [uploading, setUploading] = useState(false);
	function submitHandler(event) {
		event.preventDefault();
		setUploading(true);
		const form = new FormData(event.target);
		form.append("action", "uploadFile");
		form.append("sciezka", "/banery/");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				const form = new FormData(event.target);
				form.append("action", "addBiogazownia");
				form.append("obraz", res);
				form.delete("plik");
				console.log(...form.entries());
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				})
					.then((res) => res.text())
					.then((res) => console.log(res))
					.then(() => {
						reload((a) => !a);
						setUploading(false);
					});
			});
	}

	return (
		<div style={{ textAlign: "left" }}>
			<Form onSubmit={submitHandler}>
				<Row>
					<FormGroup as={Col}>
						<FormLabel>Nazwa</FormLabel>
						<FormControl
							name="nazwa"
							type="text"
							placeholder="nazwa biogazowni"
						></FormControl>
					</FormGroup>
					<FormGroup as={Col}>
						<FormLabel>Posiadacz</FormLabel>
						<FormSelect name="posiadacz">
							<option value="" style={{ color: "gray" }}>
								--brak--
							</option>
							{konta
								.filter((a) => a.typ === "3")
								.map((a, i) => (
									<option key={i} value={a.id}>
										{a.nazwa}
									</option>
								))}
						</FormSelect>
					</FormGroup>
				</Row>
				<Row>
					<FormGroup as={Col}>
						<FormLabel>Baner</FormLabel>
						<FormControl type="file" name="plik"></FormControl>
					</FormGroup>
				</Row>
				<Row>
					<Col>
						<Button type="submit" disabled={uploading}>
							{uploading ? "Przesyłanie..." : "Dodaj"}
						</Button>
					</Col>
				</Row>
			</Form>
		</div>
	);
}
