import "./Technologiczny.css";
import Czesci from "../../components/Czesci";
import { Col, Row, Table } from "react-bootstrap";
import Zalecenia from "../../components/Zalecenia";
import Przeglady, { PrzegladyForm } from "../../components/Przeglady";
import Instrukcje, { InstrukcjeForm } from "../../components/Instrukcje";
import { ZaleceniaForm } from "../../components/Zalecenia";
import TechnologicznyGlowny from "./TechnologicznyGlowny";

export default function Technologiczny(props) {
	const { selBiogaz, loggedIn, reload, refresh, page } = props;
	switch (page) {
		case "pzp":
			return (
				<div>
					<h2>Plan i zakres przeglądów</h2>
					<Row>
						<Col>
							<Przeglady
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								przegmode={["t", "a"]}
								uprawnienia={loggedIn.typ === "22" || loggedIn.typ === "1"}
							></Przeglady>
						</Col>
						{(loggedIn.typ === "22" || loggedIn.typ === "1") && (
							<Col md="2">
								<PrzegladyForm
									selBiogaz={selBiogaz}
									loggedIn={loggedIn}
									reload={reload}
									refresh={refresh}
									przegmode={"t"}
								></PrzegladyForm>
							</Col>
						)}
					</Row>
				</div>
			);
		case "zam":
			return (
				<div className="Technologiczny">
					<h2>Zamówienia</h2>
					<Row>
						<Col>
							<Czesci
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								typ="technologiczny"
								uprawnienia={loggedIn.typ === "22" || loggedIn.typ === "1"}
							></Czesci>
						</Col>
						{/* {(loggedIn.typ === "22" || loggedIn.typ === "1") && (
              <Col md="2">
                Do zrobienia formularz, który będzie pozwalał na przypisywanie
                części do biogazowni
              </Col>
            )} */}
					</Row>
				</div>
			);
		case "hnp":
			return (
				<div>
					<h2>Historia napraw i przeglądów</h2>
					<Row>
						<Col>
							<Przeglady
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								przegmode={["t", "h"]}
								uprawnienia={loggedIn.typ === "22" || loggedIn.typ === "1"}
							></Przeglady>
						</Col>
						{loggedIn.typ === "1" ||
							(loggedIn.typ === "3" && (
								<Col md={2}>
									<PrzegladyForm
										selBiogaz={selBiogaz}
										loggedIn={loggedIn}
										reload={reload}
										refresh={refresh}
										przegmode={"t"}
										wykonano
									></PrzegladyForm>
								</Col>
							))}
					</Row>
				</div>
			);
		case "zal":
			return (
				<div>
					<h2>Zalecenia</h2>
					<Row>
						<Col>
							<Zalecenia
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								zalmode={"t"}
								uprawnienia={loggedIn.typ === "22" || loggedIn.typ === "1"}
							></Zalecenia>
						</Col>
						{loggedIn.typ === "22" || loggedIn.typ === "1" ? (
							<Col md={2}>
								<ZaleceniaForm
									selBiogaz={selBiogaz}
									loggedIn={loggedIn}
									reload={reload}
									refresh={refresh}
									zalmode={"t"}
								></ZaleceniaForm>
							</Col>
						) : (
							<Col>
								<h2>Dodatkowe usługi</h2>
								<ul>
									<li>Przeglądy instalacji ppoż.,</li>
									<li>Przeglądy instalacji elektrycznych,</li>
									<li>Czyszczenie zbiorników,</li>
									<li>Usuwanie skorup przy pomocy Agrimixera,</li>
									<li>Repowering,</li>
									<li>Transformacja do biometanowni.</li>
								</ul>
							</Col>
						)}
					</Row>
				</div>
			);
		case "ins":
			return (
				<div>
					<h2>Instrukcje</h2>
					<Row>
						<Col>
							<Instrukcje
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								insmode={"t"}
								uprawnienia={loggedIn.typ === "22" || loggedIn.typ === "1"}
							></Instrukcje>
						</Col>
						{(loggedIn.typ === "22" || loggedIn.typ === "1") && (
							<Col md="2">
								<InstrukcjeForm
									selBiogaz={selBiogaz}
									loggedIn={loggedIn}
									reload={reload}
									refresh={refresh}
									insmode={"t"}
									uprawnienia={loggedIn.typ === "22" || loggedIn.typ === "1"}
									selected={["b", selBiogaz.id]}
								></InstrukcjeForm>
							</Col>
						)}
					</Row>
				</div>
			);
		default:
			return (
				<TechnologicznyGlowny
					selBiogaz={selBiogaz}
					loggedIn={loggedIn}
					reload={reload}
					refresh={refresh}
				></TechnologicznyGlowny>
			);
	}
}
