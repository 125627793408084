import "./PanelSerwis.css";
import Zalecenia, { ZaleceniaForm } from "../components/Zalecenia";
import { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import Przeglady, { PrzegladyForm } from "../components/Przeglady";
import { KalendarzForm } from "../components/Kalendarz";

export default function PanelSerwis(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	const [serwisTyp, setSerwisTyp] = useState(["", ""]);
	useEffect(() => {
		switch (loggedIn.typ[1]) {
			case "1":
				setSerwisTyp(["bs", "Panel Serwisu Biologicznego"]);
				break;
			case "2":
				setSerwisTyp(["ts", "Panel Serwisu Technologicznego"]);
				break;
			case "3":
				setSerwisTyp(["ks", "Panel Serwisu Kogeneracji"]);
				break;
			default:
				break;
		}
	}, [loggedIn]);

	switch (loggedIn.typ[1]) {
		case "1":
			return (
				<div className="panelserwis">
					<center>
						<h2>{serwisTyp[1]}</h2>
					</center>
					<Tabs justify defaultActiveKey="zalecenia">
						<Tab eventKey="wbmf" title="Wyniki badań masy fermentacyjnej">
							<div>
								<center>
									<h4>Wyniki badań masy fermentacyjnej</h4>
								</center>
								<Zalecenia
									selBiogaz={selBiogaz}
									loggedIn={loggedIn}
									reload={reload}
									refresh={refresh}
									zalmode={"fs"}
								></Zalecenia>
							</div>
						</Tab>
						{/* <Tab eventKey="zalecenia" title="Zalecenia">
              <div>
                <center>
                  <h4>Zalecenia</h4>
                </center>
                <Zalecenia
                  selBiogaz={selBiogaz}
                  loggedIn={loggedIn}
                  reload={reload}
                  refresh={refresh}
                  zalmode={serwisTyp[0]}
                ></Zalecenia>
                <ZaleceniaForm
                  loggedIn={loggedIn}
                  reload={reload}
                  refresh={refresh}
                  zalmode={serwisTyp[0]}
                ></ZaleceniaForm>
              </div>
            </Tab> */}
						<Tab eventKey="badania" title="Pozostałe badania">
							<div>
								<center>
									<h4>Pozostałe badania</h4>
								</center>
								<Zalecenia
									selBiogaz={selBiogaz}
									loggedIn={loggedIn}
									reload={reload}
									refresh={refresh}
									zalmode={"ps"}
								></Zalecenia>
							</div>
						</Tab>
						<Tab eventKey="kalendarz" title="Kalendarz">
							<div>
								<center>
									<h4>Kalendarz</h4>
								</center>
								<KalendarzForm
									loggedIn={loggedIn}
									refresh={refresh}
									reload={reload}
								></KalendarzForm>
							</div>
						</Tab>
					</Tabs>
				</div>
			);
		case "2":
			return (
				<div className="panelserwis">
					<center>
						<h2>{serwisTyp[1]}</h2>
					</center>
					<Tabs justify defaultActiveKey="zalecenia">
						<Tab title="Plan i zakres serwisów" eventKey="pzp">
							<div>
								<center>
									<h4>Plan i zakres serwisów</h4>
								</center>
								<Row>
									<Col>
										<Przeglady
											loggedIn={loggedIn}
											reload={reload}
											refresh={refresh}
											przegmode={["t", "a"]}
										></Przeglady>
									</Col>
									<Col md={2}>
										<PrzegladyForm
											loggedIn={loggedIn}
											reload={reload}
											refresh={refresh}
											przegmode={"t"}
										></PrzegladyForm>
									</Col>
								</Row>
							</div>
						</Tab>
						<Tab title="Historia napraw i przeglądów" eventKey="hnp">
							<div>
								<center>
									<h4>Historia napraw i przeglądów</h4>
								</center>
								<Row>
									<Col>
										<Przeglady
											loggedIn={loggedIn}
											reload={reload}
											refresh={refresh}
											przegmode={["t", "h"]}
										></Przeglady>
									</Col>
									<Col md={2}>
										<PrzegladyForm
											loggedIn={loggedIn}
											reload={reload}
											refresh={refresh}
											przegmode={"t"}
										></PrzegladyForm>
									</Col>
								</Row>
							</div>
						</Tab>
						{/* <Tab title="Zalecenia" eventKey="zal">
							<div>
								<center>
									<h4>Zalecenia</h4>
								</center>
								<Row>
									<Col>
										<Zalecenia
											selBiogaz={selBiogaz}
											loggedIn={loggedIn}
											reload={reload}
											refresh={refresh}
											zalmode={"ts"}
										></Zalecenia>
									</Col>
									<Col md={2}>
										<ZaleceniaForm
											loggedIn={loggedIn}
											reload={reload}
											refresh={refresh}
											zalmode={serwisTyp[0]}
										></ZaleceniaForm>
									</Col>
								</Row>
							</div>
						</Tab> */}
						<Tab eventKey="kalendarz" title="Kalendarz">
							<div>
								<center>
									<h4>Kalendarz</h4>
								</center>
								<KalendarzForm
									loggedIn={loggedIn}
									refresh={refresh}
									reload={reload}
								></KalendarzForm>
							</div>
						</Tab>
					</Tabs>
				</div>
			);
		case "3":
			return (
				<div className="panelserwis">
					<center>
						<h2>{serwisTyp[1]}</h2>
					</center>
					<Tabs justify defaultActiveKey="zalecenia">
						<Tab title="Plan i zakres serwisów" eventKey="pzp">
							<div>
								<center>
									<h4>Plan i zakres serwisów</h4>
								</center>
								<Row>
									<Col>
										<Przeglady
											loggedIn={loggedIn}
											reload={reload}
											refresh={refresh}
											przegmode={["k", "a"]}
										></Przeglady>
									</Col>
									<Col md={2}>
										<PrzegladyForm
											loggedIn={loggedIn}
											reload={reload}
											refresh={refresh}
											przegmode={"k"}
										></PrzegladyForm>
									</Col>
								</Row>
							</div>
						</Tab>
						<Tab title="Historia napraw i przeglądów" eventKey="hnp">
							<div>
								<center>
									<h4>Historia napraw i przeglądów</h4>
								</center>
								<Row>
									<Col>
										<Przeglady
											uprawnienia
											loggedIn={loggedIn}
											reload={reload}
											refresh={refresh}
											przegmode={["k", "h"]}
										></Przeglady>
									</Col>
									<Col md={2}>
										<PrzegladyForm
											loggedIn={loggedIn}
											reload={reload}
											refresh={refresh}
											przegmode={"k"}
											wykonano
										></PrzegladyForm>
									</Col>
								</Row>
							</div>
						</Tab>
						{/* <Tab title="Zalecenia" eventKey="zal">
							<div>
								<center>
									<h4>Zalecenia</h4>
								</center>
								<Row>
									<Col>
										<Zalecenia
											selBiogaz={selBiogaz}
											loggedIn={loggedIn}
											reload={reload}
											refresh={refresh}
											zalmode={"ks"}
										></Zalecenia>
									</Col>
									<Col md={2}>
										<ZaleceniaForm
											loggedIn={loggedIn}
											reload={reload}
											refresh={refresh}
											zalmode={serwisTyp[0]}
										></ZaleceniaForm>
									</Col>
								</Row>
							</div>
						</Tab> */}
						<Tab eventKey="kalendarz" title="Kalendarz">
							<div>
								<center>
									<h4>Kalendarz</h4>
								</center>
								<KalendarzForm
									loggedIn={loggedIn}
									refresh={refresh}
									reload={reload}
								></KalendarzForm>
							</div>
						</Tab>
					</Tabs>
				</div>
			);
		default:
			break;
	}
}
