import "./Footer.css";

export default function Footer() {
	return (
		<>
			{" "}
			<div className="Footer">
				<span>© agriKomp Polska Sp. z o.o.</span>
			</div>
			<div className="FooterFiller">
				{" "}
				<span style={{ marginRight: "auto" }}>
					<span style={{ marginLeft: "2rem" }}></span>Wersja deweloperska
				</span>
			</div>
			<div className="FooterFiller2"></div>
		</>
	);
}
