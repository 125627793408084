import {
  Alert,
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import { apiUrl, mode, useRef } from "../globsls";
import { useState } from "react";

import "./Login.css";

export default function Login(props) {
  const { setLoggedIn } = props;
  const [tried, setTried] = useState(false);
  function loginHandler(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("action", "login");
    fetch(apiUrl, {
      mode: mode,
      method: "POST",
      body: form,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === "0") {
          setTried(true);
        } else {
          document.cookie = "loggedIn=" + JSON.stringify(res);
          setLoggedIn(res[0]);
        }
      });
  }

  return (
    <div className="Login">
      <Form onSubmit={loginHandler}>
        <h1>Logowanie do platformy Agriservice</h1>
        <br></br>
        <FormGroup>
          <FormControl
            required
            placeholder="login, email albo nazwa konta"
            type="text"
            name="login"
          ></FormControl>
          <br></br>
          <FormControl
            required
            placeholder="hasło"
            type="password"
            name="password"
          ></FormControl>
        </FormGroup>
        <br></br>
        <Button type="submit" style={{ marginTop: 0 }}>
          Zaloguj
        </Button>
        {tried && (
          <Alert
            className="error"
            variant="warning"
            onClose={() => setTried(false)}
            dismissible
          >
            Niepoprawne dane logowania
          </Alert>
        )}
      </Form>
    </div>
  );
}
