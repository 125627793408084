import { useRef, useState } from "react";
import {
	ModalBody,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	Tab,
	TabContainer,
	TabContent,
	TabPane,
	Form,
	FormGroup,
	Button,
	FormLabel,
	FormControl,
	Row,
	Col,
	FormSelect,
} from "react-bootstrap";
import { ZaleceniaForm } from "./Zalecenia.js";
import { PrzegladyForm } from "./Przeglady";
import { apiUrl, mode } from "../../globsls.js";

function formatDate(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [year, month, day].join("-");
}

export default function AddWydarzenieForm({
	reload,
	selBiogaz,
	loggedIn,
	data,
	selectedOptions,
}) {
	const [key, setKey] = useState("ww");
	const opcje = {
		21: [
			["zalecenie^f", "Badanie fermentacji"],
			["zalecenie^p", "Inne badanie"],
		],
		1: [["admin", "Zdarzenie admina"]],
	};
	const nazwaRef = useRef(null);
	const opisRef = useRef(null);
	const rodzajRef = useRef(null);
	const dataRef = useRef(null);
	const kolorRef = useRef(null);
	function submit(event) {
		if (selBiogaz) {
			const form = new FormData();
			form.append("action", "addWydarzenia");
			form.append("user", JSON.stringify(loggedIn));
			form.append("biogazownia", selBiogaz.id);
			form.append("dataStart", formatDate(data));
			form.append("nazwa", nazwaRef.current.value);
			form.append("opis", opisRef.current.value);
			form.append("rodzaj", rodzajRef.current.value);
			form.append("dataStop", dataRef.current.value || formatDate(data));
			form.append("kolor", kolorRef.current.value);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => reload((a) => !a));
		} else {
			console.log("skibidi");
			selectedOptions.forEach((a) => {
				const form = new FormData();
				form.append("action", "addWydarzenia");
				form.append("user", JSON.stringify(loggedIn));
				form.append("biogazownia", a.value);
				form.append("dataStart", formatDate(data));
				form.append("nazwa", nazwaRef.current.value);
				form.append("opis", opisRef.current.value);
				form.append("rodzaj", rodzajRef.current.value);
				form.append("dataStop", dataRef.current.value || formatDate(data));
				form.append("kolor", kolorRef.current.value);
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				}).then(() => reload((a) => !a));
			});
		}
	}
	return (
		<>
			<ModalHeader closeButton>Dodaj wydarzenie</ModalHeader>
			<div style={{ width: "100%" }}>
				<Nav variant="tabs" fill justify style={{ marginTop: "0.25rem" }}>
					<NavItem>
						<NavLink onClick={() => setKey("ww")} active={key === "ww"}>
							Wydarzenie własne
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink onClick={() => setKey("bmf")} active={key === "bmf"}>
							Badanie masy fermentacyjnej
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink onClick={() => setKey("bb")} active={key === "bb"}>
							Badanie biologiczne
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink onClick={() => setKey("pt")} active={key === "pt"}>
							Przegląd technologiczny
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink onClick={() => setKey("pk")} active={key === "pt"}>
							Przegląd kogeneracji
						</NavLink>
					</NavItem>
				</Nav>
			</div>
			<ModalBody style={{ padding: "1rem 5rem" }}>
				<TabContainer activeKey={key} onSelect={setKey} className="mb-3">
					<TabContent>
						<TabPane eventKey={"ww"}>
							<Form>
								<FormGroup>
									<FormLabel>Nazwa wydarzenia</FormLabel>
									<FormControl
										type="text"
										required
										ref={nazwaRef}
									></FormControl>
								</FormGroup>
								<FormGroup>
									<FormLabel>Opis wydarzenia</FormLabel>
									<FormControl as="textarea" ref={opisRef}></FormControl>
								</FormGroup>
								<FormGroup>
									<FormLabel>Cykliczność</FormLabel>
									<Row>
										<Col>
											<FormLabel>Rodzaj</FormLabel>
											<FormSelect ref={rodzajRef}>
												<option value="">brak</option>
												<option value={"m"}>co miesiąc</option>
												<option value={"w"}>co tydzień</option>
												<option value={"d"}>codziennie</option>
												<option value={"y"}>co roku</option>
												<option value={"k"}>co kwartał</option>
											</FormSelect>
										</Col>
										<Col>
											<FormLabel>Do</FormLabel>
											<FormControl type="date" ref={dataRef}></FormControl>
										</Col>
									</Row>
								</FormGroup>
								<FormGroup style={{ marginBottom: "1em" }}>
									<FormLabel>Typ</FormLabel>
									<FormSelect ref={kolorRef}>
										<option value="^b">Serwis biologiczny</option>
										<option value="^t">Serwis biologiczny</option>
										<option value="^k">Serwis kogeneracji</option>
										<option value="secondary">Zwykłe zdarzenie</option>
									</FormSelect>
								</FormGroup>
								<Button variant="primary" onClick={() => submit()}>
									Dodaj
								</Button>
							</Form>
						</TabPane>
						<TabPane eventKey={"bmf"}>
							<ZaleceniaForm
								selBiogaz={selBiogaz}
								zalmode={"f"}
								data={data}
								loggedIn={loggedIn}
								reload={reload}
							/>
						</TabPane>
						<TabPane eventKey={"bb"}>
							<ZaleceniaForm
								selBiogaz={selBiogaz}
								zalmode={"b"}
								data={data}
								loggedIn={loggedIn}
								reload={reload}
							/>
						</TabPane>
						<TabPane eventKey={"pt"}>
							{/* Content for "Przegląd technologiczny" */}
							<PrzegladyForm
								przegmode={"t"}
								selBiogaz={selBiogaz}
								zalmode={"b"}
								data={data}
								loggedIn={loggedIn}
								reload={reload}
							></PrzegladyForm>
						</TabPane>
						<TabPane eventKey={"pk"}>
							{/* Content for "Przegląd technologiczny" */}
							<PrzegladyForm
								przegmode={"k"}
								selBiogaz={selBiogaz}
								zalmode={"b"}
								data={data}
								loggedIn={loggedIn}
								reload={reload}
							></PrzegladyForm>
						</TabPane>
					</TabContent>
				</TabContainer>
			</ModalBody>
		</>
	);
}
