import "./Glowny.css";
import { apiUrl, mode, uploadsUrl } from "../../../globsls";

import Kalendarz from "../../components/Kalendarz";
import Zalecenia from "../../components/Glowny/Zalecenia";
import Alerty from "../../components/Glowny/Alerty";
import Diagnoza from "../../components/Glowny/Diagnoza";
import {
	Carousel,
	Col,
	Row,
	Image,
	CarouselCaption,
	Tabs,
	Tab,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import Footer from "../../Footer";

export default function BiologicznyGlowny(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	const [slides, setSlides] = useState([[selBiogaz.plik, null]]);
	const [index, setIndex] = useState(0);
	const handleSelect = (selectedIndex) => {
		setIndex(selectedIndex);
	};
	useEffect(() => {
		let buffer = [];
		const form = new FormData();
		form.append("action", "getReklamy");
		form.append("tryb", "b");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				res.forEach((a) => {
					// buffer.push([selBiogaz.plik, null]);
					buffer.push([a.plik, a.link]);
				});
				setSlides(buffer);
				setIndex(0);
			});
	}, [selBiogaz]);
	return (
		<div className="Glowny">
			<div>
				{slides.length > 0 ? (
					<Carousel
						interval={10000}
						indicators={false}
						activeIndex={index}
						onSelect={handleSelect}
					>
						{slides.map((a, i) => (
							<Carousel.Item key={i}>
								{a[1] ? (
									<>
										<a
											href={a[1]}
											target="_BLANK"
											rel="noreferrer"
											className="slajdlink"
										>
											<Image src={uploadsUrl + a[0]}></Image>
										</a>
										<CarouselCaption>
											<a href={a[1]} className="slajdOpis">
												{/* <h3>Zapoznaj się z ofertą</h3> */}
											</a>
										</CarouselCaption>
									</>
								) : (
									<span>
										<Image src={uploadsUrl + a[0]}></Image>
									</span>
								)}
							</Carousel.Item>
						))}
					</Carousel>
				) : (
					<></>
				)}
			</div>
			<div
				style={{ marginTop: "1rem", display: "flex", justifyContent: "center" }}
			>
				{" "}
				<h1>Serwis biologiczny</h1>
			</div>

			<Row>
				<Col
					style={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
						width: "60%",
						marginLeft: "2rem",
					}}
				>
					<div style={{ fontSize: "xx-large", width: "80%", textAlign: "" }}>
						<b>OFERTA LABORATORYJNA</b>
						<br></br>
						DLA EFEKTYWNEGO PRZEBIEGU PROCESÓW W TWOJEJ BIOGAZOWNI
					</div>
					<div
						style={{
							width: "100%",
							height: "3px",
							background: "#0e9dd9",
							boxShadow: "1px 1px 2px #0e9dd9",
							margin: "1rem",
						}}
					></div>
					<div
						style={{ fontSize: "x-large", width: "80%", textAlign: "justify" }}
					>
						Dysponujemy własnym laboratorium biotechnologicznym wyposażonym w
						specjalistyczny sprzęt pomiarowy i analityczny dostosowany do
						specyfiki funkcjonowania instalacji biogazowych, który pozwala
						naszym specjalistom przeprowadzić szereg badań z zakresu
						monitorowania jakości substratów, składu gazów, temperatury, pH,
						oraz aktywności mikroorganizmów.{" "}
					</div>
					<br></br> <br></br>
					<div
						style={{ fontSize: "xx-large", width: "80%", textAlign: "" }}
						className="bioTab"
					>
						<b>CO BADAMY?</b>
						<br></br>
						<Tabs fill style={{ fontSize: "1rem" }}>
							<Tab eventKey={"substraty"} title="Substraty">
								Badanie fermentacji pozwala na określenie ilości i jakości
								biogazu jaki można otrzymać podczas procesu fermentacji
								metanowej z danego substratu. Przede wszystkim oznacza się
								procentowy udział metanu, dwutlenku węgla i siarkowodoru w
								otrzymanym gazie oraz uzysk podany w m3 na jedną tonę świeżej
								masy. W ramach tego badania oznacza się również suchą masę oraz
								suchą masę organiczną badanego substratu. <br></br>
								<br></br> Oferujemy nie tylko rzetelne wyniki badań, ale również
								wsparcie w ich interpretacji oraz przygotowywane indywidulanie
								zalecenia dotyczące optymalizacji procesu. Co więcej, w razie
								potrzeby pomagamy dobrać zbilansowane preparaty, aby zapewnić
								jak największą efektywność i zmniejszyć koszty stosowanych
								substratów.
								<br></br>
							</Tab>
							<Tab eventKey={"masa"} title="Masa fermentacyjna">
								Badanie fermentacji pozwala na określenie ilości i jakości
								biogazu jaki można otrzymać podczas procesu fermentacji
								metanowej z danego substratu. Przede wszystkim oznacza się
								procentowy udział metanu, dwutlenku węgla i siarkowodoru w
								otrzymanym gazie oraz uzysk podany w m3 na jedną tonę świeżej
								masy. W ramach tego badania oznacza się również suchą masę oraz
								suchą masę organiczną badanego substratu.
							</Tab>
							<Tab eventKey={"tubstraty"} title="Biogaz">
								Badanie inhibicji jest procedurą mającą na celu ocenę
								potencjalnie szkodliwego wpływu danego substratu na ogólny
								proces fermentacji i produkcję biogazu. W ramach tego badania,
								próbka zostaje poddana skróconemu procesowi fermentacji. Jeżeli
								wyniki badań wykazują brak produkcji lub stosunkowo niewielką
								ilość biogazu w założonym okresie czasu, oznacza to, że badany
								substrat działa hamująco na proces fermentacji. Również dla
								takiej sytuacji przygotujemy opis analityczny z wykresem wyników
								oraz wskazaniami.
							</Tab>
							<Tab eventKey={"yubstraty"} title="Poferment">
								Regularne monitorowanie parametrów masy fermentacyjnej jest
								obligatoryjną procedurą, która umożliwia utrzymanie odpowiednich
								warunków oraz zapewnienie właściwego żywienia bakterii, mając na
								celu zachowanie ich zdrowia i sprawności. <br></br>
								<br></br>
								Dowiedz się więcej w zakładce badań masy fermentacyjnej serwisu
								biologicznego
							</Tab>
						</Tabs>
					</div>
				</Col>
				<Col style={{ width: "40%" }}>
					<img
						src={"/glownyBio.png"}
						alt="obraz"
						style={{
							width: "80%",
							height: "60rem",
							objectFit: "cover",
							justifySelf: "left",
							marginRight: "-1.5rem",
							float: "right",
						}}
					></img>
				</Col>
			</Row>
			<Footer></Footer>
		</div>
	);
}
