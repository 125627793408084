export const root = "https://agriservice.pl/";
export const apiUrl = root + "PHP/api.php";
export const mode = "cors";
export const uploadsUrl = "https://agriservice.pl/uploads/";
export const email = "a@a.com";
export function convertZalMode(a) {
	switch (a) {
		case "b":
			return "/5. Pozostałe";
		case "t":
			return "/5. Pozostałe";
		case "k":
			return "/5. Pozostałe";
		case "p":
			return "/3. Serwis/2. Serwis biologiczny/2. Pozostałe badania";
		case "f":
			return "/3. Serwis/2. Serwis biologiczny/1. Badania masy fermentacyjnej";
		default:
			return "";
	}
}
let test = 10;

export const getCurrentDate = () => {
	const currentDate = new Date();
	const year = currentDate.getFullYear();
	const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
	const day = String(currentDate.getDate()).padStart(2, "0");
	return `${year}-${month}-${day}`;
};

export function convertPrzegMode(a) {
	switch (a) {
		case "W2":
			return `
        regulacja luzów zaworowych • wymiana świec zapłonowych • kontrola przerwy 0,30 mm FM •
        kontrola czujnika dymu + protokół • wymiana/ kontrola czujnika gazu + protokół • kontrola kompresji
        i strat cylindrów + diagram ciśnienia sprężania • kontrola sterowania wentylatorów chłodnic i wyrzutni
        (VSD) • wymiana filtra powietrza • kontrola dokręcenia obejm zaciskowych 6,5 Nm • kontrola izolacji
        układu wydechowego • Kontrola czujnika NOx • kontrola mocowań silnika i kompensatorów
      `;
		case "W3":
			return `
        regulacja luzów zaworowych • wymiana świec zapłonowych • kontrola przerwy 0,30 mm FM •
        kontrola czujnika dymu + protokół • kontrola kompresji i strat cylindrów + diagram ciśnienia sprężania
        • kontrola sterowania wentylatorów chłodnic i wyrzutni (VSD) • wymiana filtra powietrza • kontrola
        dokręcenia obejm zaciskowych 6,5 Nm • kontrola izolacji układu wydechowego • Kontrola czujnika
        NOx • kontrola mocowań silnika i kompensatorów • wymiana uszczelek chłodnicy olejowej DC13 •
        kable zapłonowe • wymiana łożysk głównych i korbowodowych wału korbowego • inspekcja łożysk
        oporowych przy wymianie łożysk wału • możliwa wymiana
      `;
		case "W4":
			return `
        regulacja luzów zaworowych • wymiana świec zapłonowych kontrola przerwy 0,30 mm FM • kontrola
        czujnika dymu + protokół kontrola kompresji i strat cylindrów + diagram kontrola ciśnienia sprężania •
        kontrola sterowania wentylatorów chłodnic i wyrzutni (VSD) • wymiana filtra powietrza • kontrola
        dokręcenia obejm zaciskowych 6,5 Nm • kontrola izolacji układu wydechowego kontrola czujnika
        NOx • kontrola mocowań silnika i kompensatorów wymiana koła zębatego wałka rozrządu i śrub
      `;
		default:
			return a;
	}
}
