import "./PanelAdmin.css";
import { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import AdminBiogazownie from "./Administratora/AdminBiogazownie";
import AdminKonta from "./Administratora/AdminKonta";
import AdminCzesci from "./Administratora/AdminCzesci";
import AdminAplikacja from "./Administratora/AdminAplikacja";

export default function PanelAdmin(props) {
  const { selBiogaz, loggedIn, reload, refresh } = props;
  return (
    <div className="PanelAdmin">
      <center>
        <h2>Panel Administratora</h2>
        <Tabs defaultActiveKey="biogazownie" justify>
          <Tab eventKey="biogazownie" title="Biogazownie">
            <AdminBiogazownie
              loggedIn={loggedIn}
              selBiogaz={selBiogaz}
              reload={reload}
              refresh={refresh}
            ></AdminBiogazownie>
          </Tab>
          <Tab eventKey="konta" title="Konta">
            <AdminKonta
              loggedIn={loggedIn}
              selBiogaz={selBiogaz}
              reload={reload}
              refresh={refresh}
            ></AdminKonta>
          </Tab>
          <Tab eventKey="czesci" title="Części">
            <AdminCzesci
              loggedIn={loggedIn}
              selBiogaz={selBiogaz}
              reload={reload}
              refresh={refresh}
            ></AdminCzesci>
          </Tab>
          <Tab eventKey="aplikacja" title="Aplikacja">
            <AdminAplikacja
              loggedIn={loggedIn}
              reload={reload}
              refresh={refresh}
            ></AdminAplikacja>
          </Tab>
        </Tabs>
      </center>
    </div>
  );
}
