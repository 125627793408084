import "./Zalecenia.css";
import { apiUrl, mode, uploadsUrl, convertZalMode } from "../../globsls";
import { useEffect, useState, useRef } from "react";
import {
	Button,
	Col,
	Form,
	FormControl,
	FormGroup,
	FormLabel,
	FormSelect,
	Row,
	Table,
	Modal,
	ModalBody,
	ModalHeader,
} from "react-bootstrap";
import ReactSelect from "react-select";
import SortIndicator from "./SortIndicator";

export default function Zalecenia(props) {
	const { selBiogaz, loggedIn, reload, refresh, zalmode, uprawnienia } = props;
	if (props.zalmode[1] !== "s") {
		return (
			<ZaleceniaBiogaz
				selBiogaz={selBiogaz}
				loggedIn={loggedIn}
				reload={reload}
				refresh={refresh}
				zalmode={zalmode}
				uprawnienia={uprawnienia}
			></ZaleceniaBiogaz>
		);
	} else {
		return (
			<ZaleceniaSerwis
				selBiogaz={selBiogaz}
				loggedIn={loggedIn}
				reload={reload}
				refresh={refresh}
				zalmode={zalmode}
				uprawnienia={uprawnienia}
			></ZaleceniaSerwis>
		);
	}
}

function ZaleceniaBiogaz(props) {
	const { selBiogaz, loggedIn, reload, refresh, uprawnienia } = props;
	const zalmode = props.zalmode[0];
	const [zalecenia, setZalecenia] = useState([]);
	const [sortBy, setSortBy] = useState("data"); // Default sorting by 'data'
	const [sortDir, setSortDir] = useState("asc"); // Ascending by default
	const [biogazownie, setBiogazowanie] = useState([]);

	// Sorting function
	function sortowanie(a, b) {
		let comparison = 0;
		if (sortBy === "data") {
			comparison = new Date(a.data) - new Date(b.data);
		} else if (sortBy === "tresc") {
			comparison = a.tresc.localeCompare(b.tresc);
		}
		// Reverse if descending
		return sortDir === "asc" ? comparison : -comparison;
	}

	// Fetch Zalecenia
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getZalecenia");
		props.zalmode[1] !== "s" && form.append("id", selBiogaz.id);
		form.append("tryb", zalmode);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setZalecenia(res));
	}, [selBiogaz, zalmode, refresh]);

	// Fetch Biogazownie
	useEffect(() => {
		if (props.zalmode[1] === "s") {
			const form = new FormData();
			form.append("action", "getBiogazownie");
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => setBiogazowanie(res));
		}
	}, [selBiogaz, zalmode, refresh]);

	// Handle sorting column and direction
	const handleSort = (column) => {
		if (sortBy === column) {
			setSortDir(sortDir === "asc" ? "desc" : "asc"); // Toggle sorting direction
		} else {
			setSortBy(column);
			setSortDir("asc"); // Default to ascending when switching columns
		}
	};

	// Display depending on permissions
	const isUserWithPermissions = uprawnienia;

	// Render
	return (
		<div className="Zalecenia">
			{/* <Row>
				<Col md="auto" style={{ fontSize: "22px" }}>
					Sortuj według
				</Col>
				<Col>
					<ReactSelect
						defaultValue={{ label: "Data rosnąco", value: "data" }}
						options={[
							{ label: "Data rosnąco", value: "data" },
							{ label: "Data malejąco", value: "datad" },
						]}
						onChange={(event) => {
							setSortBy(event.value.startsWith("data") ? "data" : "tresc");
							setSortDir(event.value.endsWith("d") ? "desc" : "asc");
						}}
					></ReactSelect>
				</Col>
			</Row> */}

			<Table striped hover>
				<thead style={{ userSelect: "none" }}>
					<tr>
						<th
							onClick={() => handleSort("data")}
							style={{ cursor: "pointer" }}
						>
							Data{" "}
							<span className="sort-icons">
								<SortIndicator
									mode={
										sortBy === "data"
											? sortDir === "asc"
												? "asc"
												: "desc"
											: ""
									}
								></SortIndicator>
							</span>
						</th>
						<th>Plik</th>
						<th
							onClick={() => handleSort("tresc")}
							style={{ cursor: "pointer" }}
						>
							Zalecenia{" "}
							<span className="sort-icons">
								<SortIndicator
									mode={
										sortBy === "tresc"
											? sortDir === "asc"
												? "asc"
												: "desc"
											: ""
									}
								></SortIndicator>
							</span>
						</th>
						{isUserWithPermissions && <th>Czynności</th>}
					</tr>
				</thead>
				<tbody>
					{zalecenia.sort(sortowanie).map((a, i) => (
						<ZalRow
							a={a}
							reload={reload}
							loggedIn={loggedIn}
							selBiogaz={selBiogaz}
							zalmode={zalmode}
						></ZalRow>
					))}
				</tbody>
			</Table>
		</div>
	);
}

function ZalRow(props) {
	const { a, reload, loggedIn, zalmode, selBiogaz } = props;
	const [show, setShow] = useState(false);
	function delHandler() {
		if (
			window.confirm(
				`Czy na pewno chcesz usunąć zalecenie lub badanie ${
					a.tresc + " " + a.data
				}? Ta zmiana jest nieodwracalna!`
			)
		) {
			const form = new FormData();
			form.append("action", "delZalecenie");
			form.append("id", a.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => reload((b) => !b));
		}
	}
	const [uploading, setUploading] = useState(false);
	const [changeFile, setChangeFile] = useState(false);

	const dataRef = useRef(null);
	const trescRef = useRef(null);
	const plikRef = useRef(null);

	function submitHandler(event) {
		if (changeFile) {
			setUploading(true);
			const form = new FormData();
			form.append("action", "uploadFile");
			form.append(
				"sciezka",
				"/biogazowanie/" + selBiogaz.nazwa + convertZalMode(zalmode) + "/"
			);
			form.append("plik", plikRef.current.files[0]);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => {
					console.log(res);
					const form2 = new FormData();
					form2.append("action", "edytujZalecenie");
					form2.append("id", a.id);
					form2.append("data", dataRef.current.value);
					form2.append("tresc", trescRef.current.value);
					form2.append("plik", res);
					fetch(apiUrl, {
						mode: mode,
						method: "POST",
						body: form2,
					})
						.then((res) => res.text())
						.then((res) => {
							setUploading(false);
							reload((a) => !a);
							setShow(false);
						});
				});
		} else {
			const form = new FormData();
			form.append("action", "edytujZalecenie");
			form.append("id", a.id);
			form.append("data", dataRef.current.value);
			form.append("tresc", trescRef.current.value);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => {
					reload((a) => !a);
					setShow(false);
				});
		}
	}
	return (
		<>
			<tr>
				<td>{a.data}</td>
				<td>
					<a href={uploadsUrl + a.plik} target="_BLANK" rel="noreferrer">
						{a.nazwapliku}
					</a>
				</td>
				<td>{a.tresc}</td>
				{(loggedIn.typ === "21" || loggedIn.typ === "1") && (
					<td style={{ width: "60px" }}>
						<Row>
							<Col>
								<Button variant="danger" size="sm" onClick={() => delHandler()}>
									Usuń
								</Button>
							</Col>
							<Col>
								<Button
									variant="primary"
									size="sm"
									onClick={() => setShow((a) => !a)}
								>
									edytuj
								</Button>
							</Col>
						</Row>
					</td>
				)}
			</tr>
			<Modal show={show} onHide={() => setShow(false)}>
				<ModalHeader>
					<h4 style={{ marginBottom: "0px" }}>Edycja badania</h4>
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup>
							<FormLabel>Data</FormLabel>
							<FormControl
								name="data"
								placeholder="Data"
								type="date"
								defaultValue={a.data}
								ref={dataRef}
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>Zalecenia</FormLabel>
							<FormControl
								name="tresc"
								placeholder="zalecenia"
								defaultValue={a.tresc}
								ref={trescRef}
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>Plik</FormLabel>
							<br></br>
							<Button
								onClick={() => setChangeFile((a) => !a)}
								style={{ margin: "0px 0px 0.5rem 0px" }}
							>
								{!changeFile ? "Zmień plik" : "Anulj zmianę pliku"}
							</Button>
							{changeFile && (
								<FormControl
									name="plik"
									placeholder="Plik"
									type="file"
									ref={plikRef}
								></FormControl>
							)}
						</FormGroup>
					</Form>
				</ModalBody>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Anuluj
					</Button>
					<Button
						variant="primary"
						onClick={() => submitHandler()}
						disabled={uploading}
					>
						{uploading ? "Przesyłanie..." : "Dodaj"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function ZaleceniaSerwis(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	const zalmode = props.zalmode[0];
	const [zalecenia, setZalecenia] = useState([]);
	const [fBiogaz, setFBiogaz] = useState([]);
	const [fDotyczy, setFdotyczy] = useState([]);
	const [fF1, setFf1] = useState([]);
	const [fF2, setFf2] = useState([]);
	const [biogazownie, setBiogazowanie] = useState([]);
	function delHandler(a) {
		if (
			window.confirm(
				`Czy na pewno chcesz usunąć zalecenie lub badanie ${
					a.tresc + " " + a.data
				}? Ta zmiana jest nieodwracalna!`
			)
		) {
			const form = new FormData();
			form.append("action", "delZalecenie");
			form.append("id", a.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => reload((a) => !a));
		}
	}
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getZalecenia");
		props.zalmode[1] !== "s" && form.append("id", selBiogaz.id);
		form.append("tryb", zalmode);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setZalecenia(res));
	}, [selBiogaz, zalmode, refresh]);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getBiogazownie");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setBiogazowanie(res));
	}, [selBiogaz, zalmode, refresh]);
	const [flagi, setFlagi] = useState([
		[[{ label: null, value: null }], [{ label: null, value: null }]],
		[[{ label: null, value: null }], [{ label: null, value: null }]],
		[[{ label: null, value: null }], [{ label: null, value: null }]],
	]);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getFlagi");
		form.append("id", selBiogaz.id);
		form.append("tryb", zalmode);
		form.append("flaga", "flaga1");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setFlagi(res));
	}, [selBiogaz, zalmode]);
	const [sortuj, setSortuj] = useState("dr");
	function sortowanie(a, b) {
		const typ = sortuj[0];
		const kierunek = sortuj[1];
		switch (typ) {
			case "d":
				return (a.data < b.data) ^ (kierunek === "r") ? 1 : -1;
			case "z":
				return (a.tresc < b.tresc) ^ (kierunek === "r") ? 1 : -1;
			case "n":
				return (a.nazwa < b.nazwa) ^ (kierunek === "r") ? 1 : -1;
			default:
				return 0;
		}
	}
	return (
		<div className="Zalecenia">
			<Row>
				<Col md="auto" style={{ fontSize: "22px" }}>
					Sortuj według
				</Col>
				<Col>
					<ReactSelect
						defaultValue={{ label: "Data rosnąco", value: "dr" }}
						options={[
							{ label: "Data rosnąco", value: "dr" },
							{ label: "Data malejąco", value: "dm" },
							{ label: "Nazwa rosnąco", value: "nr" },
							{ label: "Nazwa malejąco", value: "nm" },
						]}
						onChange={(event) => setSortuj(event.value)}
					></ReactSelect>
				</Col>
			</Row>
			<Row>
				<Col>
					Biogazownia
					<ReactSelect
						onChange={(event) => setFBiogaz(event.map((a) => a.value))}
						isMulti
						options={biogazownie.map((a) => ({ value: a.id, label: a.nazwa }))}
					></ReactSelect>
				</Col>
				<Col>
					Dotyczy
					<ReactSelect
						onChange={(event) => setFdotyczy(event.map((a) => a.value))}
						isMulti
						options={zalecenia
							.map((a) => a.dotyczy)
							.filter((a) => a !== null)
							.filter(function (item, pos, self) {
								return self.indexOf(item) === pos;
							})
							.map((a) => ({ label: a.replace("#", " "), value: a }))}
					></ReactSelect>
				</Col>
				<Col>
					Tag 1
					<ReactSelect
						onChange={(event) => setFf1(event.map((a) => a.value))}
						isMulti
						options={zalecenia
							.map((a) => a.flaga1)
							.filter((a) => a !== null)
							.filter(function (item, pos, self) {
								return self.indexOf(item) === pos;
							})
							.map((a) => ({ label: a.replace("#", " "), value: a }))}
					></ReactSelect>
				</Col>
				<Col>
					Tag 2
					<ReactSelect
						onChange={(event) => setFf2(event.map((a) => a.value))}
						isMulti
						options={zalecenia
							.map((a) => a.flaga2)
							.filter((a) => a !== null)
							.filter(function (item, pos, self) {
								return self.indexOf(item) === pos;
							})
							.map((a) => ({ label: a.replace("#", " "), value: a }))}
					></ReactSelect>
				</Col>
			</Row>
			<Table striped hover>
				<thead>
					<tr>
						{props.zalmode[1] === "s" && <th>Biogazownia</th>}
						{(zalmode === "p" || props.zalmode[1] === "s") && <th>Nazwa</th>}
						<th>Data</th>
						<th>Plik</th>
						<th>Zalecenia</th>
						{props.zalmode[1] === "s" && <th>Dotyczy</th>}
						{props.zalmode[1] === "s" && <th>Tag 1 (problem)</th>}
						{props.zalmode[1] === "s" && <th>Tag 2 (rozwiązanie)</th>}
						{props.uprawnienia && <th>Czynności</th>}
					</tr>
				</thead>
				<tbody>
					{zalecenia
						.filter((a) =>
							fDotyczy.length > 0 ? fDotyczy.includes(a.dotyczy) : 1
						)
						.filter((a) =>
							fBiogaz.length > 0 ? fBiogaz.includes(a.biogazownia) : 1
						)
						.filter((a) => (fF2.length > 0 ? fF2.includes(a.flaga2) : 1))
						.filter((a) => (fF1.length > 0 ? fF1.includes(a.flaga1) : 1))
						.map((a, i) => (
							<tr>
								{props.zalmode[1] === "s" && <td>{a.biogaznazwa}</td>}
								{(zalmode === "p" || props.zalmode[1] === "s") && (
									<td>{a.nazwa}</td>
								)}
								<td>{a.data}</td>
								<td>
									<a
										href={uploadsUrl + a.plik}
										target="_BLANK"
										rel="noreferrer"
									>
										{a.nazwapliku}
									</a>
								</td>
								<td>{a.tresc}</td>
								{a.dotyczy ? (
									props.zalmode[1] === "s" && (
										<td>{a.dotyczy.replace("#", " ")}</td>
									)
								) : (
									<td></td>
								)}
								<td>{a.flaga1}</td>
								<td>{a.flaga2}</td>
								<td>
									<Button
										variant="danger"
										size="sm"
										onClick={() => delHandler(a)}
									>
										test
									</Button>
								</td>
							</tr>
						))}
				</tbody>
			</Table>
		</div>
	);
}

function formatDate(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [year, month, day].join("-");
}

export function ZaleceniaForm(props) {
	const { selBiogaz, loggedIn, reload, zalmode, data } = props;
	const [loading, setLoading] = useState(true);
	const [uploadingFile, setUploadingFile] = useState(false);
	const dataRef = useRef(null);
	const nazwaRef = useRef(null);
	const trescRef = useRef(null);
	const plikRef = useRef(null);
	const dotyczy1Ref = useRef(null);
	const dotyczy2Ref = useRef(null);
	const flaga1Ref = useRef(null);
	const flaga2Ref = useRef(null);
	const [selectedOptions, setSelectedOptions] = useState([]);

	const [nowyD, setNowyD] = useState(false);
	const [nowy1, setNowy1] = useState(false);
	const [nowy2, setNowy2] = useState(false);

	const [biogazownie, setBiogazownie] = useState([]);

	const [flagi, setFlagi] = useState([
		[[{ label: null, value: null }], [{ label: null, value: null }]],
		[[{ label: null, value: null }], [{ label: null, value: null }]],
		[[{ label: null, value: null }], [{ label: null, value: null }]],
	]);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getFlagi");
		if (selBiogaz) {
			form.append("id", selBiogaz.id);
		}

		form.append("tryb", zalmode);
		form.append("flaga", "flaga1");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setFlagi(res))
			.then(() => setLoading(false));
		if (!selBiogaz) {
			const form2 = new FormData();
			form2.append("action", "getBiogazownie");
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form2,
			})
				.then((res) => res.json())
				.then((res) => setBiogazownie(res));
		}
	}, [selBiogaz, zalmode]);
	const [czesciTypy, setCzesciTypy] = useState([]);

	useEffect(() => {
		const form = new FormData();
		form.append("action", "getCzesciTypy");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setCzesciTypy(res));
	}, [selBiogaz, zalmode]);

	function submitHandler(event) {
		event.preventDefault();
		console.log("submitting");
		if (plikRef.current.files[0]) {
			setUploadingFile(true);
			if (selBiogaz) {
				const form = new FormData();
				form.append("action", "uploadFile");
				form.append(
					"sciezka",
					"/biogazowanie/" + selBiogaz.nazwa + convertZalMode(zalmode) + "/"
				);
				form.append("plik", plikRef.current.files[0]);
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				})
					.then((res) => res.json())
					.then((res) => {
						const form = new FormData();
						form.append("action", "addZalecenie");
						form.append("biogazownia", selBiogaz.id);
						form.append("tryb", zalmode);
						form.append("data", dataRef.current.value);
						form.append("nazwa", nazwaRef.current.value);
						form.append("tresc", trescRef.current.value);
						form.append("plik", res);
						form.append(
							"dotyczy",
							nowyD
								? (dotyczy1Ref.current.getValue().length > 0
										? dotyczy1Ref.current.getValue()[0].value
										: "") +
										"#" +
										dotyczy2Ref.current.value
								: dotyczy1Ref.current.getValue().length > 0
									? dotyczy1Ref.current.getValue()[0].value
									: ""
						);
						form.append(
							"flaga1",
							nowy1
								? flaga1Ref.current.value
								: flaga1Ref.current.getValue().length > 0
									? flaga1Ref.current.getValue()[0].value
									: ""
						);
						form.append(
							"flaga2",
							nowy2
								? flaga2Ref.current.value
								: flaga2Ref.current.getValue().length > 0
									? flaga2Ref.current.getValue()[0].value
									: ""
						);
						fetch(apiUrl, {
							mode: mode,
							method: "POST",
							body: form,
						})
							.then((res) => reload((a) => !a))
							.then(() => setUploadingFile(false));
					});
				event.preventDefault();
			} else {
				setUploadingFile(true);
				selectedOptions.forEach((a) => {
					const form = new FormData();
					form.append("action", "uploadFile");
					form.append(
						"sciezka",
						"/biogazowanie/" + a.label + convertZalMode(zalmode) + "/"
					);
					form.append("plik", plikRef.current.files[0]);
					fetch(apiUrl, {
						mode: mode,
						method: "POST",
						body: form,
					})
						.then((res) => res.json())
						.then((res) => {
							const form = new FormData();
							form.append("action", "addZalecenie");
							form.append("biogazownia", a.value);
							form.append("tryb", zalmode);
							form.append("data", dataRef.current.value);
							form.append("nazwa", nazwaRef.current.value);
							form.append("tresc", trescRef.current.value);
							form.append("plik", res);
							form.append(
								"dotyczy",
								nowyD
									? (dotyczy1Ref.current.getValue().length > 0
											? dotyczy1Ref.current.getValue()[0].value
											: "") +
											"#" +
											dotyczy2Ref.current.value
									: dotyczy1Ref.current.getValue().length > 0
										? dotyczy1Ref.current.getValue()[0].value
										: ""
							);
							form.append(
								"flaga1",
								nowy1
									? flaga1Ref.current.value
									: flaga1Ref.current.getValue().length > 0
										? flaga1Ref.current.getValue()[0].value
										: ""
							);
							form.append(
								"flaga2",
								nowy2
									? flaga2Ref.current.value
									: flaga2Ref.current.getValue().length > 0
										? flaga2Ref.current.getValue()[0].value
										: ""
							);
							fetch(apiUrl, {
								mode: mode,
								method: "POST",
								body: form,
							})
								.then((res) => reload((a) => !a))
								.then(() => setUploadingFile(false));
						});
					event.preventDefault();
					setUploadingFile(false);
				});
			}
		} else {
			if (selBiogaz) {
				const form = new FormData();
				form.append("action", "addZalecenie");
				form.append("biogazownia", selBiogaz.id);
				form.append("tryb", zalmode);
				form.append("data", dataRef.current.value || formatDate(data));
				form.append("nazwa", nazwaRef.current.value);
				form.append("tresc", trescRef.current.value);
				form.append(
					"dotyczy",
					nowyD
						? (dotyczy1Ref.current.getValue().length > 0
								? dotyczy1Ref.current.getValue()[0].value
								: "") +
								"#" +
								dotyczy2Ref.current.value
						: dotyczy1Ref.current.getValue().length > 0
							? dotyczy1Ref.current.getValue()[0].value
							: ""
				);
				form.append(
					"flaga1",
					nowy1
						? flaga1Ref.current.value
						: flaga1Ref.current.getValue().length > 0
							? flaga1Ref.current.getValue()[0].value
							: ""
				);
				form.append(
					"flaga2",
					nowy2
						? flaga2Ref.current.value
						: flaga2Ref.current.getValue().length > 0
							? flaga2Ref.current.getValue()[0].value
							: ""
				);
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				})
					.then((res) => res.text())
					.then((res) => console.log(res))
					.then((res) => reload((a) => !a))
					.then(() => setUploadingFile(false));
				event.preventDefault();
			} else {
				selectedOptions.forEach((a) => {
					const form = new FormData();
					form.append("action", "addZalecenie");
					form.append("biogazownia", a.value);
					form.append("tryb", zalmode);
					form.append("data", dataRef.current.value || formatDate(data));
					form.append("nazwa", nazwaRef.current.value);
					form.append("tresc", trescRef.current.value);
					form.append(
						"dotyczy",
						nowyD
							? (dotyczy1Ref.current.getValue().length > 0
									? dotyczy1Ref.current.getValue()[0].value
									: "") +
									"#" +
									dotyczy2Ref.current.value
							: dotyczy1Ref.current.getValue().length > 0
								? dotyczy1Ref.current.getValue()[0].value
								: ""
					);
					form.append(
						"flaga1",
						nowy1
							? flaga1Ref.current.value
							: flaga1Ref.current.getValue().length > 0
								? flaga1Ref.current.getValue()[0].value
								: ""
					);
					form.append(
						"flaga2",
						nowy2
							? flaga2Ref.current.value
							: flaga2Ref.current.getValue().length > 0
								? flaga2Ref.current.getValue()[0].value
								: ""
					);
					fetch(apiUrl, {
						mode: mode,
						method: "POST",
						body: form,
					})
						.then((res) => res.text())
						.then((res) => console.log(res))
						.then((res) => reload((a) => !a))
						.then(() => setUploadingFile(false));
				});
				event.preventDefault();
				setUploadingFile(false);
			}
		}
	}
	const handleChange = (selected) => {
		if (selected && selected.some((option) => option.value === "select-all")) {
			if (selectedOptions.length === biogazownie.length) {
				setSelectedOptions([]);
			} else {
				setSelectedOptions(biogazownie);
			}
		} else {
			setSelectedOptions(selected);
		}
	};
	if (!loading)
		return (
			<Form className="zalForm" onSubmit={submitHandler}>
				{!selBiogaz && (
					<FormGroup
						style={{
							borderBottom: "1px solid lightgray",
							paddingBottom: "0.5em",
						}}
					>
						<FormLabel>Biogazownie</FormLabel>
						<ReactSelect
							isMulti
							options={biogazownie.map((a) => ({
								value: a.id,
								label: a.nazwa,
							}))}
							value={selectedOptions}
							onChange={handleChange}
							closeMenuOnSelect={false}
						></ReactSelect>
						<Button
							onClick={() =>
								setSelectedOptions(
									biogazownie.map((a) => ({
										value: a.id,
										label: a.nazwa,
									}))
								)
							}
						>
							Wybierz wszystkie
						</Button>
					</FormGroup>
				)}

				<FormGroup hidden={data}>
					<FormLabel>Data</FormLabel>
					<FormControl type="date" ref={dataRef}></FormControl>
				</FormGroup>
				<FormGroup>
					<FormLabel>Nazwa</FormLabel>
					<FormControl type="text" ref={nazwaRef}></FormControl>
				</FormGroup>
				<FormGroup>
					<FormLabel>Zalecenia</FormLabel>
					<FormControl type="text" ref={trescRef}></FormControl>
				</FormGroup>
				<FormGroup>
					<FormLabel>Plik</FormLabel>
					<FormControl type="file" ref={plikRef} name="file"></FormControl>
				</FormGroup>
				<FormGroup>
					<FormLabel>Dotyczy</FormLabel>
					{nowyD ? (
						<div>
							<Row style={{ marginLeft: 10 }}>
								<Col style={{ marginLeft: -22 }}>
									Urządzenie
									<ReactSelect
										ref={dotyczy1Ref}
										options={[
											{ value: "", label: "inny (nie używaj listy)" },
											{
												label: "części w biogazowniach",
												options: czesciTypy.map((a) => ({
													label: a.nazwa,
													value: a.nazwa,
												})),
											},
										]}
									></ReactSelect>
								</Col>
								<Col>
									Wariant
									<FormControl type="text" ref={dotyczy2Ref}></FormControl>
								</Col>
							</Row>
						</div>
					) : (
						<div>
							<ReactSelect
								ref={dotyczy1Ref}
								options={[
									{
										label: selBiogaz
											? "Już użyte w tej biogazowni"
											: "Używane w tej kategorii",
										options: flagi[0][0]
											.map((a) => ({
												label: a[Object.keys(a)[0]],
												value: a[Object.keys(a)[0]],
											}))
											.filter((a) => a.label)
											.map((a) => ({
												label: a.label.replace("#", " "),
												value: a.value,
											})),
									},
									{
										label: selBiogaz
											? "Już użyte w innych biogazowniach"
											: "Używane w tej kategorii",
										options: flagi[0][1]
											.map((a) => ({
												label: a[Object.keys(a)[0]],
												value: a[Object.keys(a)[0]],
											}))
											.filter((a) => a.label)
											.map((a) => ({
												label: a.label.replace("#", " "),
												value: a.value,
											})),
									},
								]}
							></ReactSelect>
						</div>
					)}
					<Button onClick={() => setNowyD((a) => !a)}>
						{nowyD ? "Zmień na istniejący" : "Dodaj nieistniejący"}
					</Button>
				</FormGroup>
				<FormGroup>
					<FormLabel>Tag 1 (problem)</FormLabel>
					{nowy1 ? (
						<div>
							<FormControl ref={flaga1Ref}></FormControl>
						</div>
					) : (
						<div>
							<ReactSelect
								ref={flaga1Ref}
								options={[
									{
										label: selBiogaz
											? "Już użyte w tej biogazowni"
											: "Używane w tej kategorii",
										options: flagi[1][0]
											.map((a) => ({
												label: a[Object.keys(a)[0]],
												value: a[Object.keys(a)[0]],
											}))
											.filter((a) => a.label),
									},
									{
										label: "Już użyte w innych biogazowniach",
										options: flagi[1][1]
											.map((a) => ({
												label: a[Object.keys(a)[0]],
												value: a[Object.keys(a)[0]],
											}))
											.filter((a) => a.label),
									},
								]}
							></ReactSelect>
						</div>
					)}
					<Button onClick={() => setNowy1((a) => !a)}>
						{nowy1 ? "Zmień na istniejący" : "Dodaj nieistniejący"}
					</Button>
				</FormGroup>
				<FormGroup>
					<FormLabel>Tag 2 (rozwiązanie)</FormLabel>
					{nowy2 ? (
						<div>
							<FormControl ref={flaga2Ref}></FormControl>
						</div>
					) : (
						<div>
							<ReactSelect
								ref={flaga2Ref}
								options={[
									{
										label: selBiogaz
											? "Już użyte w tej biogazowni"
											: "Używane w tej kategorii",
										options: flagi[2][0]
											.map((a) => ({
												label: a[Object.keys(a)[0]],
												value: a[Object.keys(a)[0]],
											}))
											.filter((a) => a.label),
									},
									{
										label: "Już użyte w innych biogazowniach",
										options: flagi[2][1]
											.map((a) => ({
												label: a[Object.keys(a)[0]],
												value: a[Object.keys(a)[0]],
											}))
											.filter((a) => a.label),
									},
								]}
							></ReactSelect>
						</div>
					)}
					<Button onClick={() => setNowy2((a) => !a)}>
						{nowy2 ? "Zmień na istniejący" : "Dodaj nieistniejący"}
					</Button>
				</FormGroup>

				<FormGroup style={{ marginTop: "0.5rem" }}>
					<Button type="submit" disabled={uploadingFile}>
						{uploadingFile ? "Przesyłanie..." : "Dodaj"}
					</Button>
				</FormGroup>
			</Form>
		);
}
