import "./Glowny.css";
import { apiUrl, mode, uploadsUrl } from "../../../globsls";

import Kalendarz from "../../components/Kalendarz";
import Zalecenia from "../../components/Glowny/Zalecenia";
import Alerty from "../../components/Glowny/Alerty";
import Diagnoza from "../../components/Glowny/Diagnoza";
import {
	Carousel,
	Col,
	Row,
	Image,
	CarouselCaption,
	Tab,
	Tabs,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import Footer from "../../Footer";

export default function TechnologicznyGlowny(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	const [slides, setSlides] = useState([[selBiogaz.plik, null]]);
	const [index, setIndex] = useState(0);
	const handleSelect = (selectedIndex) => {
		setIndex(selectedIndex);
	};
	useEffect(() => {
		let buffer = [];
		const form = new FormData();
		form.append("action", "getReklamy");
		form.append("tryb", "t");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				res.forEach((a) => {
					// buffer.push([selBiogaz.plik, null]);
					buffer.push([a.plik, a.link]);
				});
				setSlides(buffer);
				setIndex(0);
			});
	}, [selBiogaz]);
	return (
		<div className="Glowny">
			<div>
				{slides.length > 0 ? (
					<Carousel
						interval={10000}
						indicators={false}
						activeIndex={index}
						onSelect={handleSelect}
					>
						{slides.map((a, i) => (
							<Carousel.Item key={i}>
								{a[1] ? (
									<>
										<a
											href={a[1]}
											target="_BLANK"
											rel="noreferrer"
											className="slajdlink"
										>
											<Image src={uploadsUrl + a[0]}></Image>
										</a>
										<CarouselCaption>
											<a href={a[1]} className="slajdOpis">
												{/* <h3>Zapoznaj się z ofertą</h3> */}
											</a>
										</CarouselCaption>
									</>
								) : (
									<span>
										<Image src={uploadsUrl + a[0]}></Image>
									</span>
								)}
							</Carousel.Item>
						))}
					</Carousel>
				) : (
					<></>
				)}
			</div>
			<div
				style={{ marginTop: "1rem", display: "flex", justifyContent: "center" }}
			>
				<h1>Serwis technologiczny</h1>
			</div>
			<Row>
				<Col
					style={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
						width: "60%",
						marginLeft: "2rem",
					}}
				>
					<div style={{ fontSize: "xx-large", width: "80%", textAlign: "" }}>
						<b>REPOWERING</b>
						<br></br>
						CZYLI DRUGIE ŻYCIE BIOGAZOWNI
					</div>
					<div
						style={{
							width: "100%",
							height: "3px",
							background: "#0e9dd9",
							boxShadow: "1px 1px 2px #0e9dd9",
							margin: "1rem",
						}}
					></div>
					<div
						style={{ fontSize: "x-large", width: "80%", textAlign: "justify" }}
					>
						Repowering biogazowni oznacza proces modernizacji istniejącej
						biogazowni w celu zwiększenia jej wydajności, efektywności
						energetycznej i ekologicznej oraz przedłużenia jej żywotności.
						Instalacja może zostać zintegrowana z nowymi urządzeniami, które
						rozszerzą wachlarz stosowanych substratów
						<br></br>
					</div>
					<br></br> <br></br>
					<div
						style={{ fontSize: "xx-large", width: "80%", textAlign: "" }}
						className="bioTab"
					>
						<b>REPOWERING OBEJMUJE:</b>
						<br></br>
						<Tabs fill style={{ fontSize: "1rem" }}>
							<Tab eventKey={"substraty"} title="Poprawa infrastruktury">
								Wymiana urządzeń oraz silników kogeneracyjnych oraz integracja z
								innymi technologiami np. z linią higienizacji lub suszarnią.
								<br></br>
							</Tab>
							<Tab
								eventKey={"masa"}
								title="Czyszczenie i zabezpieczenie wewnętrzne zbiorników"
							>
								Zapewniamy wszystkie usługi związane z czyszczeniem zbiorników,
								w tym: mycie, wentylację, monitorowanie gazu i usuwanie osadów i
								nalotów, a także pokrycie ścian folią ochronną;
								<br></br>
							</Tab>
							<Tab
								eventKey={"tubstraty"}
								title="Usuwanie zastojów w zbiornikach"
							>
								Skuteczne usuwanie zastojów, pływających skorup oraz osadów
								zebranych na dnie w zbiornikach, studzienkach i lagunach.
							</Tab>
							<Tab eventKey={"yubstraty"} title="Zwiększenie wydajności">
								Ulepszanie procesów fermentacji i produkcji biogazu poprzez
								zastosowanie nowoczesnych komponentów, które zwiększają
								efektywność przemiany organicznych surowców w biogaz.
							</Tab>
							<Tab eventKey={"rws"} title="Restart wsparcia finansowego">
								Uruchomienie, wznowienie, kontynuację systemów wsparcia
								finansowego w ramach modernizacji.
							</Tab>
						</Tabs>
					</div>
				</Col>
				<Col style={{ width: "40%", position: "relative" }}>
					{/* First Image with Gradient */}
					<div
						style={{
							width: "80%",
							height: "60rem",
							objectFit: "cover",
							justifySelf: "left",
							marginRight: "-1.5rem",
							float: "right",
							background:
								"linear-gradient(315deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%), url('glownyTech.png')",
							backgroundSize: "cover",
							backgroundPosition: "center",
						}}
					></div>

					{/* Second Image with Gradient */}
					<div
						style={{
							width: "80%",
							height: "60rem",
							objectFit: "cover",
							justifySelf: "left",
							marginRight: "-1.5rem",
							marginTop: "-60rem",
							float: "right",
							background:
								"linear-gradient(315deg, rgba(255, 255, 255, 0) 0%, rgba(111, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%), url('glownyTech2.png')",
							backgroundSize: "cover",
							backgroundPosition: "center",
						}}
					></div>
				</Col>
			</Row>
			<Footer></Footer>
		</div>
	);
}
