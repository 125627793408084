import { useEffect, useRef, useState } from "react";
import { apiUrl, convertPrzegMode, mode, uploadsUrl } from "../../globsls";
import "./Przeglady.css";
import {
	Button,
	Col,
	Form,
	FormCheck,
	FormControl,
	FormGroup,
	FormLabel,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Table,
} from "react-bootstrap";
import ReactSelect from "react-select";
import SortIndicator from "./SortIndicator";

export default function Przeglady(props) {
	const { selBiogaz, loggedIn, reload, refresh, przegmode, uprawnienia } =
		props;
	const [przeglady, setPrzeglady] = useState([]);
	const [sortBy, setSortBy] = useState("data"); // Default sorting by 'data'
	const [sortDir, setSortDir] = useState("asc"); // Default to ascending order

	useEffect(() => {
		const form = new FormData();
		form.append("action", "getPrzeglady");
		if (selBiogaz) {
			form.append("id", selBiogaz.id);
		}
		form.append("tryb", przegmode[0]);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setPrzeglady(res));
	}, [selBiogaz, przegmode, refresh]);

	// Sorting function
	function sortowanie(a, b) {
		let comparison = 0;

		// Sort by the selected column (sortBy)
		switch (sortBy) {
			case "data":
				comparison = new Date(a.data) - new Date(b.data);
				break;
			case "nazwa":
				comparison = a.nazwa.localeCompare(b.nazwa);
				break;
			case "zlecono":
				comparison = new Date(a.zlecono) - new Date(b.zlecono);
				break;
			case "wykonano":
				comparison = new Date(a.wykonano) - new Date(b.wykonano);
				break;
			case "status":
				comparison = new Date(a.zlecono) - new Date(b.zlecono);
				break;
			case "zakres":
				comparison = a.zakres.localeCompare(b.zakres);
				break;
			case "protokol": // or 'raport' depending on the mode
				comparison = a.protokol.localeCompare(b.protokol); // Assuming this is a string
				break;
			case "zalecenia":
				comparison = a.zalecenia.localeCompare(b.zalecenia); // Assuming this is a string
				break;
			default:
				comparison = 0;
		}

		return sortDir === "asc" ? comparison : -comparison;
	}

	// Handle sorting column and direction
	const handleSort = (column) => {
		if (sortBy === column) {
			setSortDir(sortDir === "asc" ? "desc" : "asc"); // Toggle direction
		} else {
			setSortBy(column); // Set new sort column
			setSortDir("asc"); // Default to ascending when switching columns
		}
	};

	return (
		<div className="Przeglady">
			{/* <Row>
				<Col md="auto" style={{ fontSize: "22px" }}>
					Sortuj według
				</Col>
				<Col>
					<ReactSelect
						defaultValue={{ label: "Data rosnąco", value: "dr" }}
						options={[
							{ label: "Data rosnąco", value: "dr" },
							{ label: "Data malejąco", value: "dm" },
							{ label: "Nazwa rosnąco", value: "nr" },
							{ label: "Nazwa malejąco", value: "nm" },
						]}
						onChange={(event) => {
							const value = event.value;
							if (value.startsWith("d")) {
								setSortBy("data");
							} else {
								setSortBy("nazwa");
							}
							setSortDir(value.endsWith("r") ? "asc" : "desc");
						}}
					/>
				</Col>
			</Row> */}
			<Table striped hover>
				<thead>
					<tr>
						{!selBiogaz && (
							<th
								onClick={() => handleSort("nazwa")}
								style={{ cursor: "pointer" }}
							>
								Biogazownia{" "}
								<SortIndicator mode={sortBy === "nazwa" ? sortDir : "none"} />
							</th>
						)}
						{przegmode[1] !== "a" && (
							<th
								onClick={() => handleSort("zlecono")}
								style={{ cursor: "pointer" }}
							>
								Zlecono{" "}
								<SortIndicator mode={sortBy === "zlecono" ? sortDir : "none"} />
							</th>
						)}
						{przegmode[1] !== "a" && (
							<th
								onClick={() => handleSort("wykonano")}
								style={{ cursor: "pointer" }}
							>
								Wykonano{" "}
								<SortIndicator
									mode={sortBy === "wykonano" ? sortDir : "none"}
								/>
							</th>
						)}
						<th
							onClick={() => handleSort("nazwa")}
							style={{ cursor: "pointer" }}
						>
							Nazwa{" "}
							<SortIndicator mode={sortBy === "nazwa" ? sortDir : "none"} />
						</th>
						{przegmode[1] === "a" && (
							<th
								onClick={() => handleSort("data")}
								style={{ cursor: "pointer" }}
							>
								Planowana data{" "}
								<SortIndicator mode={sortBy === "data" ? sortDir : "none"} />
							</th>
						)}
						<th
							onClick={() => handleSort("zakres")}
							style={{ cursor: "pointer" }}
						>
							Zakres{" "}
							<SortIndicator mode={sortBy === "zakres" ? sortDir : "none"} />
						</th>
						{przegmode[1] !== "a" && (
							<th
								onClick={() => handleSort("protokol")}
								style={{ cursor: "pointer" }}
							>
								{przegmode[0] === "k" ? "Protokół" : "Raport"}{" "}
								<SortIndicator
									mode={sortBy === "protokol" ? sortDir : "none"}
								/>
							</th>
						)}
						{przegmode[1] === "a" && (
							<th
								onClick={() => handleSort("status")}
								style={{ cursor: "pointer" }}
							>
								Status{" "}
								<SortIndicator mode={sortBy === "status" ? sortDir : "none"} />
							</th>
						)}
						{przegmode[1] !== "a" && (
							<th
								onClick={() => handleSort("zalecenia")}
								style={{ cursor: "pointer" }}
							>
								Zalecenia{" "}
								<SortIndicator
									mode={sortBy === "zalecenia" ? sortDir : "none"}
								/>
							</th>
						)}
						{uprawnienia && <th>Czynności</th>}
					</tr>
				</thead>
				<tbody>
					{przeglady
						.sort(sortowanie)
						.filter((b) => (przegmode[1] === "a" ? !b.wykonano : true))
						.filter((b) => (przegmode[1] === "h" ? b.wykonano : true))
						.map((a, i) => (
							<PrzegRow
								key={i}
								a={a}
								przegmode={przegmode}
								uprawnienia={uprawnienia}
								reload={reload}
								selBiogaz={selBiogaz}
							/>
						))}
				</tbody>
			</Table>
		</div>
	);
}

function PrzegRow(props) {
	const { a, przegmode, reload, uprawnienia, selBiogaz } = props;
	const [zlecono, setZlecono] = useState(a.zlecono ? true : false);
	const [wykonano, setWykonano] = useState(a.wykonano ? true : false);
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);
	useEffect(() => {
		setZlecono(a.zlecono ? true : false);
		setWykonano(a.wykonano ? true : false);
	}, [a, przegmode]);
	function statusHandler(typ) {
		let todo = "";
		if (typ === "z") {
			setZlecono((a) => !a);
			if (a.zlecono) {
				todo = "zn";
			} else {
				if (
					window.confirm(
						"pamiętaj, że zaznaczenie zlecenia jest informacyjne i nie odpowiada za zlecenie przeglądu"
					)
				) {
					todo = "zd";
				}
			}
		} else if (a.wykonano) {
			setWykonano((a) => !a);
			todo = "wn";
		} else {
			todo = "wd";
		}
		const form = new FormData();
		form.append("action", "przegladStatus");
		form.append("id", a.id);
		form.append("todo", todo);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.text())
			.then((res) => console.log(res))
			.then(() => reload((a) => !a));
	}
	const zaleceniaRef = useRef("");
	const plikRef = useRef("");
	const [uploading, setUploading] = useState(false);
	function wykonaj() {
		const form = new FormData();
		form.append("action", "uploadFile");
		setUploading(true);
		form.append(
			"sciezka",
			"/biogazowanie/" +
				selBiogaz.nazwa +
				"/3. Serwis/" +
				(przegmode[0] === "t"
					? "3. Serwis technologiczny/1. Raporty serwisowe"
					: "4. Serwis kogeneracji/1. Raporty i protokoły") +
				"/"
		);
		form.append("plik", plikRef.current.files[0]);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				const form = new FormData();
				form.append("action", "przegladWykonaj");
				form.append("id", a.id);
				form.append("plik", res);
				form.append("zalecenia", zaleceniaRef.current.value);
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				})
					.then((res) => res.text())
					.then((res) => console.log(res))
					.then(() => {
						setShow(false);
						reload((b) => !b);
						setUploading(false);
					});
			});
	}
	function delHandler() {
		if (
			window.confirm(
				`Czy na pewno chcesz usunąc przegląd ${a.zakres + " " + a.data}?`
			)
		) {
			const form = new FormData();
			form.append("action", "delPrzeglad");
			form.append("id", a.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => reload((b) => !b));
		}
	}
	const [changeFile, setChangeFile] = useState(false);
	const nazwaRef = useRef(null);
	const dataRef = useRef(null);
	const zakresRef = useRef(null);
	const plikRef2 = useRef(null);
	const zaleceniaRef2 = useRef(null);

	function submitHandler() {
		const form = new FormData();
		form.append("action", "edytujPrzeglad");
		form.append("id", a.id); // Assuming you have a reference for ID
		form.append("nazwa", nazwaRef.current.value);
		form.append("data", dataRef.current.value);
		form.append("zakres", zakresRef.current.value);
		form.append("zalecenia", zaleceniaRef2.current.value);

		// Check if a file is being uploaded
		if (changeFile) {
			setUploading(true);
			const fileForm = new FormData();
			fileForm.append("action", "uploadFile");
			fileForm.append("plik", plikRef2.current.files[0]); // Get the selected file
			fileForm.append(
				"sciezka",
				"/biogazowanie/" +
					selBiogaz.nazwa +
					"/3. Serwis/" +
					(przegmode[0] === "t"
						? "3. Serwis technologiczny/1. Raporty serwisowe"
						: "4. Serwis kogeneracji/1. Raporty i protokoły") +
					"/"
			);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: fileForm,
			})
				.then((res) => res.json())
				.then((res) => {
					console.log(res);
					const plikId = res; // Assuming the response contains the file ID
					form.append("plik", plikId); // Add the file ID to the update form
					return fetch(apiUrl, {
						mode: mode,
						method: "POST",
						body: form,
					});
				})
				.then((res) => res.text())
				.then((res) => {
					console.log(res);
					setShow2(false);
					reload((a) => !a);
					setUploading(false);
					if (res.status === "success") {
						setShow2(false);
						reload((a) => !a);
						setUploading(false);
					} else {
						// Handle error
					}
				});
		} else {
			// If no file is being uploaded, just submit the form
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => {
					setShow2(false);
					reload((a) => !a);
					setUploading(false);
					if (res.status === "success") {
					} else {
						// Handle error
					}
				});
		}
	}

	function editHandler() {}
	return (
		<>
			<tr>
				{!selBiogaz && (
					<td style={{ color: a.data === "0000-00-00" ? "blue" : "black" }}>
						{a.nazwabiogazu}
					</td>
				)}
				{przegmode[1] !== "a" && (
					<td style={{ color: a.data === "0000-00-00" ? "blue" : "black" }}>
						{a.zlecono}
					</td>
				)}
				{przegmode[1] !== "a" && (
					<td style={{ color: a.data === "0000-00-00" ? "blue" : "black" }}>
						{a.wykonano}
					</td>
				)}
				<td style={{ color: a.data === "0000-00-00" ? "blue" : "black" }}>
					{a.nazwa}
				</td>
				{przegmode[1] === "a" && (
					<td style={{ color: a.data === "0000-00-00" ? "blue" : "black" }}>
						{a.data}
					</td>
				)}
				<td style={{ color: a.data === "0000-00-00" ? "blue" : "black" }}>
					{przegmode[0] === "k" ? convertPrzegMode(a.zakres) : a.zakres}
				</td>
				{przegmode[1] !== "a" && (
					<td style={{ color: a.data === "0000-00-00" ? "blue" : "black" }}>
						<a href={uploadsUrl + a.sciezka} target="_BLANK" rel="noreferrer">
							{a.plik}
						</a>
					</td>
				)}
				{przegmode[1] !== "a" && (
					<td style={{ color: a.data === "0000-00-00" ? "blue" : "black" }}>
						{a.zalecenia}
					</td>
				)}
				{przegmode[1] === "a" && (
					<td>
						<Row>
							<Col>
								Zlecono
								<FormCheck
									checked={zlecono}
									readOnly
									onClick={() => statusHandler("z")}
								></FormCheck>
							</Col>
							<Col>
								Wykonano
								<FormCheck
									readOnly
									checked={wykonano}
									onClick={() => setShow(true)}
									disabled={!uprawnienia}
								></FormCheck>
							</Col>
						</Row>
					</td>
				)}
				{uprawnienia && (
					<td>
						<Button size="sm" variant="danger" onClick={() => delHandler()}>
							Usuń
						</Button>
						<Button
							size="sm"
							variant="primary"
							onClick={() => setShow2((a) => !a)}
						>
							edytuj
						</Button>
					</td>
				)}
			</tr>
			<Modal show={show}>
				<ModalHeader>Wykonaj serwis</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup>
							<FormLabel>Raport</FormLabel>
							<FormControl
								name="plik"
								placeholder="plik"
								type="file"
								ref={plikRef}
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>Zalecenia</FormLabel>
							<FormControl
								name="zalecenia"
								placeholder="zalecenia"
								ref={zaleceniaRef}
							></FormControl>
						</FormGroup>
					</Form>
				</ModalBody>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Anuluj
					</Button>
					<Button
						variant="primary"
						onClick={() => wykonaj()}
						disabled={uploading}
					>
						{uploading ? "Przesyłanie...." : "Dodaj"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={show2} onHide={() => setShow(false)}>
				<ModalHeader>
					<h4 style={{ marginBottom: "0px" }}>Edycja przeglądu</h4>
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup>
							<FormLabel>Nazwa</FormLabel>
							<FormControl
								name="nazwa"
								placeholder="nazwa"
								ref={nazwaRef}
								defaultValue={a.nazwa}
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>Data</FormLabel>
							<FormControl
								name="Data"
								placeholder="Data"
								ref={dataRef}
								defaultValue={a.data}
								type="date"
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>Zakres</FormLabel>
							<FormControl
								name="Zakres"
								placeholder="Zakres"
								defaultValue={a.zakres}
								ref={zakresRef}
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>zalecenia</FormLabel>
							<FormControl
								name="zalecenia"
								placeholder="zalecenia"
								ref={zaleceniaRef2}
								defaultValue={a.zalecenia}
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>Plik</FormLabel>
							<br></br>
							<Button
								onClick={() => setChangeFile((a) => !a)}
								style={{ margin: "0px 0px 0.5rem 0px" }}
							>
								{changeFile ? "Zmień plik" : "Anulj zmianę pliku"}
							</Button>
							{changeFile && (
								<FormControl
									name="plik"
									placeholder="Plik"
									type="file"
									ref={plikRef2}
								></FormControl>
							)}
						</FormGroup>
					</Form>
				</ModalBody>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow2(false)}>
						Anuluj
					</Button>
					<Button
						variant="primary"
						onClick={() => submitHandler()}
						disabled={uploading}
					>
						{uploading ? "Przesyłanie..." : "Dodaj"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
function formatDate2(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [year, month, day].join("-");
}

export function PrzegladyForm(props) {
	const { selBiogaz, reload, refresh, przegmode, wykonano, data } = props;
	const [przeglady, setPrzeglady] = useState([]);
	function submitHandler(event) {
		if (!selBiogaz) {
			selectedOptions.forEach((a) => {
				event.preventDefault();
				const form = new FormData(event.target);
				if (data) {
					form.delete("data");
					form.append("data", formatDate2(data));
				}
				form.append("action", "addPrzeglad");
				form.append("biogazownia", a.value);
				form.append("tryb", przegmode);
				if (wykonano) {
					form.append("wykonano", true);
				}
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				})
					.then((res) => res.text())
					.then((res) => console.log(res))
					.then(() => reload((a) => !a));
			});
		} else {
			event.preventDefault();
			const form = new FormData(event.target);
			form.append("action", "addPrzeglad");
			if (data) {
				form.delete("data");
				form.append("data", formatDate2(data));
			}
			form.append("biogazownia", selBiogaz.id);
			form.append("tryb", przegmode);
			if (wykonano) {
				form.append("wykonano", true);
			}
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => reload((a) => !a));
		}
	}
	function addKogen(przeglad) {
		if (selBiogaz) {
			const form = new FormData();
			form.append("action", "addPrzeglad");
			form.append("biogazownia", selBiogaz.id);
			form.append("tryb", przegmode);
			form.append("data", formatDateToYYYYMMDD(przeglad[1]));
			form.append("nazwa", przeglad[0]);
			form.append("zakres", przeglad[0]);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res));
		} else {
			selectedOptions.forEach((a) => {
				const form = new FormData();
				form.append("action", "addPrzeglad");
				form.append("biogazownia", a.value);
				form.append("tryb", przegmode);
				form.append("data", formatDateToYYYYMMDD(przeglad[1]));
				form.append("nazwa", przeglad[0]);
				form.append("zakres", przeglad[0]);
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				})
					.then((res) => res.text())
					.then((res) => console.log(res));
			});
		}
	}
	function formatDateToYYYYMMDD(dateString) {
		// Split the date string into components
		const [day, month, year] = dateString.split(".").map(Number);

		// Pad the month and day with leading zeros if necessary
		const paddedMonth = String(month).padStart(2, "0");
		const paddedDay = String(day).padStart(2, "0");

		// Return the formatted date string
		return `${year}-${paddedMonth}-${paddedDay}`;
	}

	function formatDate(date) {
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
		const year = date.getFullYear();

		return `${day}.${month}.${year}`;
	}
	function generuj(event) {
		event.preventDefault();
		const form = new FormData(event.target);
		let dane = [...form.entries()];
		let [year, month, day] = dane[0][1].split("-").map(Number);
		let start = new Date(year, month - 1, day);
		let [year2, month2, day2] = dane[4][1].split("-").map(Number);
		let buffer = [];
		let stop = new Date(year2, month2 - 1, day2);
		let w2 = new Date(start.getTime());
		let w3 = new Date(start.getTime());
		let w4 = new Date(start.getTime());
		w2.setDate(w2.getDate() + parseInt(dane[1][1]));
		w3.setDate(w3.getDate() + parseInt(dane[2][1]));
		w4.setDate(w4.getDate() + parseInt(dane[3][1]));
		while (w2 <= stop) {
			buffer.push(["W2", formatDate(w2)]);
			w2.setDate(w2.getDate() + parseInt(dane[1][1]));
		}
		while (w3 <= stop) {
			buffer.push(["W3", formatDate(w3)]);
			w3.setDate(w3.getDate() + parseInt(dane[2][1]));
		}
		while (w4 <= stop) {
			buffer.push(["W4", formatDate(w4)]);
			w4.setDate(w4.getDate() + parseInt(dane[3][1]));
		}
		setPrzeglady(
			buffer.sort((a, b) => {
				// Parse the dates from DD.MM.YYYY format
				let [dayA, monthA, yearA] = a[1].split(".").map(Number);
				let [dayB, monthB, yearB] = b[1].split(".").map(Number);

				// Create Date objects
				let dateA = new Date(yearA, monthA - 1, dayA);
				let dateB = new Date(yearB, monthB - 1, dayB);

				// Compare the dates
				return dateA - dateB;
			})
		);
	}
	function zatwierdzKogen() {
		przeglady.forEach((c) => {
			addKogen(c);
		});
	}
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [biogazownie, setBiogazownie] = useState([]);
	useEffect(() => {
		if (!selBiogaz) {
			const form2 = new FormData();
			form2.append("action", "getBiogazownie");
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form2,
			})
				.then((res) => res.json())
				.then((res) => setBiogazownie(res));
		}
	}, [refresh, selBiogaz]);
	const handleChange = (selected) => {
		if (selected && selected.some((option) => option.value === "select-all")) {
			if (selectedOptions.length === biogazownie.length) {
				setSelectedOptions([]);
			} else {
				setSelectedOptions(biogazownie);
			}
		} else {
			setSelectedOptions(selected);
		}
	};
	return (
		<div>
			{przegmode || wykonano ? (
				<Form onSubmit={submitHandler}>
					{!selBiogaz && (
						<FormGroup
							style={{
								borderBottom: "1px solid lightgray",
								paddingBottom: "0.5em",
							}}
						>
							<FormLabel>Biogazownie</FormLabel>
							<ReactSelect
								isMulti
								options={biogazownie.map((a) => ({
									value: a.id,
									label: a.nazwa,
								}))}
								value={selectedOptions}
								onChange={handleChange}
								closeMenuOnSelect={false}
							></ReactSelect>
							<Button
								onClick={() =>
									setSelectedOptions(
										biogazownie.map((a) => ({
											value: a.id,
											label: a.nazwa,
										}))
									)
								}
							>
								Wybierz wszystkie
							</Button>
						</FormGroup>
					)}
					<FormGroup>
						<FormLabel>Nazwa urządzenbia</FormLabel>
						<FormControl name="nazwa" placeholder="nazwa"></FormControl>
					</FormGroup>
					{!wykonano && (
						<FormGroup hidden={data}>
							<FormLabel>Planowana data</FormLabel>
							<FormControl
								type="date"
								name="data"
								placeholder="data"
							></FormControl>
						</FormGroup>
					)}

					<FormGroup>
						<FormLabel>Zakres</FormLabel>
						<FormControl name="zakres" placeholder="zakres"></FormControl>
					</FormGroup>
					<FormGroup style={{ marginTop: "0.5rem" }}>
						<Button type="submit">Zatwierdź</Button>
					</FormGroup>
				</Form>
			) : (
				<div>
					{!selBiogaz && (
						<FormGroup
							style={{
								borderBottom: "1px solid lightgray",
								paddingBottom: "0.5em",
							}}
						>
							<FormLabel>Biogazownie</FormLabel>
							<ReactSelect
								isMulti
								options={biogazownie.map((a) => ({
									value: a.id,
									label: a.nazwa,
								}))}
								value={selectedOptions}
								onChange={handleChange}
								closeMenuOnSelect={false}
							></ReactSelect>
							<Button
								onClick={() =>
									setSelectedOptions(
										biogazownie.map((a) => ({
											value: a.id,
											label: a.nazwa,
										}))
									)
								}
							>
								Wybierz wszystkie
							</Button>
						</FormGroup>
					)}
					<Form onSubmit={generuj}>
						<FormGroup hidden={data}>
							<FormLabel>Początek serwisu</FormLabel>
							{data ? (
								<FormControl
									defaultValue={formatDate(data)}
									name="poczatek"
									placeholder="poczatek"
									type="date"
								></FormControl>
							) : (
								<FormControl
									name="poczatek"
									placeholder="poczatek"
									type="date"
								></FormControl>
							)}
						</FormGroup>
						<FormGroup hidden={data}>
							<FormLabel>Interwał w2</FormLabel>
							{data ? (
								<FormControl
									defaultValue={1}
									name="w2"
									placeholder="w2"
									type="number"
								></FormControl>
							) : (
								<FormControl
									name="w2"
									placeholder="w2"
									type="number"
									defaultValue={45}
								></FormControl>
							)}
						</FormGroup>
						<FormGroup hidden={data}>
							<FormLabel>Interwał w3</FormLabel>
							{data ? (
								<FormControl
									name="w2"
									placeholder="w2"
									type="number"
									defaultValue={1}
								></FormControl>
							) : (
								<FormControl
									name="w2"
									placeholder="w2"
									type="number"
									defaultValue={60}
								></FormControl>
							)}
						</FormGroup>
						<FormGroup hidden={data}>
							<FormLabel>Interwał w4</FormLabel>
							<FormControl
								name="w2"
								placeholder="w4"
								type="number"
								defaultValue={75}
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>Koniec serwisu</FormLabel>
							<FormControl
								name="koniec"
								placeholder="koniec"
								type="date"
							></FormControl>
						</FormGroup>
						<Button type="submit">Generuj przeglądy kogeneracji</Button>
					</Form>
					{przeglady.length > 0 && (
						<div>
							<h4>Podgląd</h4>
							<div>
								{przeglady.map((b) => (
									<div>
										{b[0]} {b[1]}
									</div>
								))}
							</div>
							<Button onClick={() => zatwierdzKogen()}>Zatwierdź</Button>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
