import "./Glowny.css";
import { apiUrl, mode, uploadsUrl } from "../../../globsls";

import Kalendarz from "../../components/Kalendarz";
import Zalecenia from "../../components/Glowny/Zalecenia";
import Alerty from "../../components/Glowny/Alerty";
import Diagnoza from "../../components/Glowny/Diagnoza";
import { Carousel, Col, Row, Image, CarouselCaption } from "react-bootstrap";
import { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";

export default function Glowny(props) {
	const { selBiogaz, loggedIn, reload, refresh, setSelBiogaz } = props;
	const [slides, setSlides] = useState([[selBiogaz.plik, null]]);
	const [index, setIndex] = useState(0);
	const handleSelect = (selectedIndex) => {
		setIndex(selectedIndex);
	};
	useEffect(() => {
		let buffer = [];
		const form = new FormData();
		form.append("action", "getReklamy");
		form.append("tryb", "aos");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.length > 0) {
					res.forEach((a) => {
						buffer.push([a.plik, null]);
					});
					buffer.push([selBiogaz.plik, null]);
				} else {
					buffer.push([selBiogaz.plik, null]);
				}
				setSlides(buffer);
				setIndex(0);
			});
	}, [selBiogaz]);
	return (
		<div className="Glowny">
			<div>
				{slides.length > 0 ? (
					<Carousel
						interval={10000}
						indicators={false}
						activeIndex={index}
						onSelect={handleSelect}
					>
						{slides.map((a, i) => (
							<Carousel.Item key={i}>
								{a[1] ? (
									<>
										<a
											href={a[1]}
											target="_BLANK"
											rel="noreferrer"
											className="slajdlink"
										>
											<Image src={uploadsUrl + a[0]}></Image>
										</a>
										<CarouselCaption>
											<a href={a[1]} className="slajdOpis">
												{/* <h3>Zapoznaj się z ofertą</h3> */}
											</a>
										</CarouselCaption>
									</>
								) : (
									<span>
										<Image src={uploadsUrl + a[0]}></Image>
									</span>
								)}
							</Carousel.Item>
						))}
					</Carousel>
				) : (
					<></>
				)}
			</div>
			<div className="glownyContainer">
				<Kalendarz
					selBiogaz={selBiogaz}
					loggedIn={loggedIn}
					reload={reload}
					refresh={refresh}
				></Kalendarz>
				<div style={{ marginLeft: "1rem" }}>
					<Row>
						<Diagnoza
							selBiogaz={selBiogaz}
							loggedIn={loggedIn}
							reload={reload}
							refresh={refresh}
							setSelBiogaz={setSelBiogaz}
						></Diagnoza>
					</Row>
					<Row>
						<Col>
							<Alerty
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
							></Alerty>
						</Col>
					</Row>
				</div>
				<div style={{ width: "100%", gridColumn: "1 / 3" }}>
					<div
						style={{
							justifyContent: "center",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<img src={"/logo.png"} alt="logo" style={{ width: "20rem" }}></img>
						<br></br>
						<span
							style={{
								fontSize: "4rem",
								justifySelf: "center",
								textAlign: "center",
								color: "#494848",
								textShadow: "1px 1px 1px gray",
							}}
						>
							NASZE USŁUGI DLA WIĘKSZEJ <br></br> EFEKTYWNOŚCI <br></br> TWOJEJ
							INSTALACJI
						</span>
						<span
							style={{
								height: "2px",
								width: "80%",
								background: "#494848",
								boxShadow: "1px 1px 1px gray",
							}}
						></span>
						<br></br>
						<div
							style={{
								height: "360px",
								overflow: "hidden",
								width: "100%",
								display: "grid",
								marginLeft: "-50%",
								alignItems: "center", // Center the content vertically
								justifyContent: "center", // Center the content horizontally
								position: "relative", // For positioning child elements
							}}
						>
							<img
								src={"/glownyImage.png"}
								alt="logo"
								style={{
									width: "30rem",
									borderRadius: "50%",
									height: "30rem",
									objectFit: "cover",
									justifySelf: "left",
								}}
							/>
							<div
								style={{
									fontSize: "1.75rem",
									color: "#494848",
									textShadow: "1px 1px 3px lightgray",
									position: "absolute", // Use absolute positioning to overlay text on the image
									left: "50%",
									transform: "translateX(-40%)", // Center horizontally
									top: "30%", // Adjust this value for vertical positioning
									textAlign: "left", // Center text
								}}
							>
								OPTYMALIZACJA <br /> NIEZAWODNOŚĆ <br /> WYSOKA WYDAJNOŚĆ
							</div>
						</div>
					</div>
					<div>
						<Footer></Footer>
					</div>
				</div>
			</div>
		</div>
	);
}
