import {
	Button,
	Col,
	Form,
	FormControl,
	FormGroup,
	FormLabel,
	FormSelect,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Table,
} from "react-bootstrap";
import "./AdminAplikacja.css";
import { apiUrl, mode } from "../../../globsls";
import { useEffect, useRef, useState } from "react";
import { KalendarzForm } from "../../components/Kalendarz";

export default function AdminAplikacja(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	return (
		<div className="AdminAplikacja">
			<h4>Opcje aplikacji</h4>
			<Row>
				<Col>
					<Reklamy
						loggedIn={loggedIn}
						selBiogaz={selBiogaz}
						reload={reload}
						refresh={refresh}
					></Reklamy>
				</Col>
				<Col style={{ textAlign: "left" }}>
					<div>
						<center>
							<h4>Kalendarz</h4>
						</center>
						<KalendarzForm
							loggedIn={loggedIn}
							refresh={refresh}
							reload={reload}
						></KalendarzForm>
					</div>
				</Col>
			</Row>
			<Row>
				<Optymalizacja
					loggedIn={loggedIn}
					selBiogaz={selBiogaz}
					reload={reload}
					refresh={refresh}
				></Optymalizacja>
			</Row>
		</div>
	);
}
const typy = {
	b: "biologiczny",
	t: "technologiczny",
	k: "kogeneracji",
	o: "ogólne",
	s: "specjalne",
};
function Reklamy(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	const [reklamy, setReklamy] = useState([]);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getReklamy");
		form.append("tryb", "btkos");
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				setReklamy(
					Object.values(
						Object.groupBy(
							res.filter((b) => b.kategoria),
							({ kategoria }) => kategoria
						)
					)
				);
			});
	}, [refresh]);
	return (
		<div>
			<h4>Reklamy</h4>
			<Table bordered>
				<thead>
					<tr className="tableHeader">
						<th>Plik</th>
						<th>Link</th>
						<th>Opcje</th>
					</tr>
				</thead>
				<tbody>
					{Object.keys(typy).map((a, i) => (
						<KategoriaReklam
							key={i}
							dane={a}
							nazwa={
								typy[a] === "ogólne"
									? "Zdjęcia biogazowni Gródek (tymczasowe rozwiązanie)"
									: typy[a]
							}
							reklamy={reklamy.filter((b) => b[0].kategoria === a)}
							reload={reload}
						></KategoriaReklam>
					))}
				</tbody>
			</Table>
		</div>
	);
}

function KategoriaReklam({ dane, reklamy, nazwa, reload }) {
	const [show, setShow] = useState(true);
	const [modal, setModal] = useState(false);
	const linkRef = useRef(null);
	const plikRef = useRef(null);
	const [uploading, setUploading] = useState(false);
	function submitHandler() {
		const form = new FormData();
		setUploading(true);
		form.append("action", "uploadFile");
		form.append("sciezka", "/reklamy/");
		form.append("plik", plikRef.current.files[0]);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				const form = new FormData();
				form.append("action", "addReklama");
				form.append("link", linkRef.current.value);
				form.append("kategoria", dane);
				form.append("plik", res);
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				})
					.then((res) => res.text())
					.then((res) => console.log(res))
					.then(() => reload((a) => !a))
					.then(() => {
						setModal(false);
						setUploading(false);
					});
			});
	}
	return (
		<>
			<tr>
				<th
					colSpan={10}
					style={{ backgroundColor: "var(--bs-warning-border-subtle)" }}
				>
					{nazwa}
					<Button
						style={{ float: "right", width: 25, height: 25, padding: 0 }}
						size="sm"
						variant="outline-secondary"
						onClick={() => setShow((a) => !a)}
					>
						{!show ? "+" : "-"}
					</Button>
				</th>
			</tr>
			{reklamy.length > 0 &&
				show &&
				reklamy[0].map((a, i) => (
					<Reklama key={i} dane={a} reload={reload}></Reklama>
				))}
			<tr hidden={!show}>
				<td colSpan={10}>
					<center>
						<Button
							variant="secondary"
							size="sm"
							className="dodajButton"
							onClick={() => setModal(true)}
						>
							dodaj
						</Button>
					</center>
				</td>
			</tr>
			<Modal show={modal} onHide={() => setModal(false)}>
				<ModalHeader>Dodawanie reklamy</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup>
							<FormLabel>Plik</FormLabel>
							<FormControl
								name="plik"
								placeholder="plik"
								ref={plikRef}
								type="file"
							></FormControl>
						</FormGroup>
						<FormGroup>
							<FormLabel>Link</FormLabel>
							<FormControl
								name="link"
								placeholder="link"
								ref={linkRef}
							></FormControl>
						</FormGroup>
					</Form>
				</ModalBody>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setModal(false)}>
						Anuluj
					</Button>
					<Button
						variant="primary"
						onClick={() => submitHandler()}
						disabled={uploading}
					>
						{uploading ? "Przesyłanie..." : "Dodaj"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
function Reklama({ dane, reload }) {
	function delHandler(id) {
		if (window.confirm("czy na pewno chcesz usunąć reklamę?")) {
			const form = new FormData();
			form.append("action", "delReklama");
			form.append("id", id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => reload((a) => !a));
		}
	}
	return (
		<tr>
			<td>{dane.plik}</td>
			<td>
				<a target="_BLANK" rel="noreferrer" href={dane.link}>
					{dane.link}
				</a>
			</td>
			<td>
				<Button size="sm" variant="danger" onClick={() => delHandler(dane.id)}>
					Usuń
				</Button>
			</td>
		</tr>
	);
}
function Optymalizacja() {
	return (
		<div style={{ width: "100%", height: "100%" }}>
			<h4>Optymalizacja</h4>
			<Button style={{ width: "100%", height: "calc(100% - 52px)" }}>
				Usuń nieużywane pliki
			</Button>
		</div>
	);
}
